<template>
	<b-modal
		v-model="open"
		:title="title"
		header-class="header-class-modal-doc-package"
		size="xs"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@show="handleShowModalFunction"
		@cancel="handleCancelModalFunction"
	>
		<template>
			<div class="d-flex flex-column gap-8 w-100">
				<div class="d-flex flex-row align-items-center">
					<div class="label">{{ FormMSG(1, 'Department name:') }}</div>
					&nbsp;
					<span> {{ row_data !== null ? row_data.departmentName : FormMSG(2, 'None') }}</span>
				</div>
				<div class="d-flex flex-column">
					<div class="d-flex align-items-center flex-row gap-8">
						<label class="w-25">{{ FormMSG(3, 'Prefix') }}</label>
						<b-form-input
							class="w-75"
							:class="{ 'is-invalid': !$v.new_function.prefix.required }"
							v-model="new_function.prefix"
							:placeholder="FormMSG(4, 'Enter a prefix name')"
						></b-form-input>
					</div>
					<div class="d-flex ml-26">
						<div v-if="!$v.new_function.prefix.required" class="invalid-feedback">{{ FormMSG(5, 'Prefix is required') }}</div>
					</div>
				</div>
				<div class="d-flex flex-column">
					<div class="d-flex align-items-center flex-row gap-8">
						<label class="w-25">{{ FormMSG(6, 'Function') }}</label>
						<b-form-input
							class="w-75"
							:class="{ 'is-invalid': !$v.new_function.message.required }"
							v-model="new_function.message"
							:placeholder="FormMSG(7, 'Enter a function name')"
						></b-form-input>
					</div>
					<div class="d-flex ml-26">
						<div v-if="!$v.new_function.message.required" class="invalid-feedback">{{ FormMSG(8, 'Function name is required') }}</div>
					</div>
				</div>
				<div class="d-flex flex-column">
					<div class="d-flex align-items-center flex-row gap-8">
						<label class="w-25">{{ FormMSG(9, 'Short code') }}</label>
						<b-form-input
							class="w-75"
							:class="{ 'is-invalid': !$v.new_function.shortCode.required }"
							v-model="new_function.shortCode"
							:placeholder="FormMSG(10, 'Enter a short code')"
						></b-form-input>
					</div>
					<div class="d-flex ml-26">
						<div v-if="!$v.new_function.shortCode.required" class="invalid-feedback">{{ FormMSG(11, 'Short code is required') }}</div>
					</div>
				</div>
			</div>
		</template>
		<template #modal-footer>
			<div class="d-flex flex-row align-items-center justify-content-end w-100 gap-8">
				<b-button variant="outline-dark" size="sm" class="btn-custom" @click="handleCancelModalFunction"> {{ FormMSG(12, 'Close') }} </b-button>
				<b-button :disabled="$v.new_function.$invalid" variant="outline-primary" size="sm" class="btn-custom" @click="handleSaveNewFunction">
					{{ FormMSG(13, 'Ok') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { validationMixin } from 'vuelidate';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { required } from 'vuelidate/lib/validators';

export default {
	name: 'NewFunction',
	data: () => ({
		new_function: {
			prefix: null,
			message: null,
			shortCode: null
		}
	}),
	mixins: [languageMessages, globalMixin, validationMixin],
	props: {
		open: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: false,
			default: ''
		},
		row_data: {
			type: Object,
			required: false,
			default: () => []
		}
	},
	watch: {
		row_data: {
			handler(newVal) {
				if (newVal) {
					let _new_function = newVal.new_function;
					if (_new_function !== undefined) {
						this.new_function = {
							...this.new_function,
							prefix: _new_function.prefix,
							message: _new_function.message,
							shortCode: _new_function.shortCode
						};
					}
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		handleShowModalFunction() {
			this.$emit('show-modal');
		},
		handleCancelModalFunction() {
			this.$emit('close-modal');
		},
		handleSaveNewFunction() {
			let data = { ...this.row_data, new_function: { ...this.row_data.new_function, ...this.new_function } };
			this.$emit('handle:submitted', data);
		}
	},
	validations() {
		const formInput = {
			new_function: {
				prefix: { required },
				message: { required },
				shortCode: { required }
			}
		};
		return formInput;
	}
};
</script>
<style scoped>
.gap-8 {
	gap: 8px;
}
.btn-custom {
	border-radius: 4px !important;
	width: 80px;
}
.ml-26 {
	margin-left: 26%;
}
input.form-control::placeholder {
	opacity: 0.5;
}
.label {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
}
</style>

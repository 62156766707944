<template>
	<div v-if="!isGrouping" class="d-flex flex-row justify-content-center align-items-center">
		<div class="d-flex flex-column justify-content-center align-items-center pd-1 mr-1 ml-1" style="width: 100%; height: 100px">
			<div class="d-flex flex-row justify-content-center align-items-start w-100 show-hover gap-2">
				<div class="d-flex flex-row" style="width: 18%">
					<b-button
						:disabled="checkSomme(item.tsDaysTotal, FIMALAC_PROJECT_FLAG.SALARY)"
						size="sm"
						class="btn bg-transparent border-0"
						:id="`tooltip-${item.id}-salaryTotalByCostCenter`"
					>
						<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" :stroke-width="2.25" />
					</b-button>
				</div>
				<b-tooltip :target="`tooltip-${item.id}-salaryTotalByCostCenter`" placement="left" class="v-bootstrap-tooltip-custom">
					<custom-tooltip :is-row="true" :item="item" :type="FIMALAC_PROJECT_FLAG.SALARY" :isProduction="isProduction" />
				</b-tooltip>
				<div class="d-flex flex-row" style="width: 82%">
					<div class="font-weight-bold fs-12 mt-1" style="text-transform: capitalize">{{ FormMSG(1, 'Salary') }} :</div>
					&nbsp;
					<div class="font-weight-bold fs-12 mt-1">{{ getCurrencyRow(item, FIMALAC_PROJECT_FLAG.SALARY) }}</div>
				</div>
			</div>
			<div class="d-flex flex-row justify-content-center align-items-start w-100 show-hover">
				<div class="d-flex flex-row" style="width: 18%">
					<b-button
						:disabled="checkSomme(item.tsDaysTotal, FIMALAC_PROJECT_FLAG.PRIME)"
						size="sm"
						class="btn bg-transparent border-0"
						:id="`tooltip-${item.id}-primeTotalByCostCenter`"
					>
						<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" :stroke-width="2.25" />
					</b-button>
				</div>
				<b-tooltip :target="`tooltip-${item.id}-primeTotalByCostCenter`" placement="left" class="v-bootstrap-tooltip-custom">
					<custom-tooltip :is-row="true" :item="item" :type="FIMALAC_PROJECT_FLAG.PRIME" :isProduction="isProduction" />
				</b-tooltip>
				<div class="d-flex flex-row" style="width: 82%">
					<div class="font-weight-bold fs-12 mt-1" style="text-transform: capitalize">{{ FormMSG(2, 'Prime') }} :</div>
					&nbsp;
					<div class="font-weight-bold fs-12 mt-1">{{ getCurrencyRow(item, FIMALAC_PROJECT_FLAG.PRIME) }}</div>
				</div>
			</div>
			<div class="d-flex flex-row justify-content-center align-items-start w-100 show-hover">
				<div class="d-flex flex-row" style="width: 18%">
					<b-button
						:disabled="checkSomme(item.tsDaysTotal, FIMALAC_PROJECT_FLAG.DEFRAYAL)"
						size="sm"
						class="btn bg-transparent border-0"
						:id="`tooltip-${item.id}-defraiment`"
					>
						<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" :stroke-width="2.25" />
					</b-button>
				</div>
				<b-tooltip :target="`tooltip-${item.id}-defraiment`" placement="left" class="v-bootstrap-tooltip-custom">
					<custom-tooltip :is-row="true" :item="item" :type="FIMALAC_PROJECT_FLAG.DEFRAYAL" :isProduction="isProduction" />
				</b-tooltip>
				<div class="d-flex flex-row" style="width: 82%">
					<div class="font-weight-bold fs-12 mt-1" style="text-transform: capitalize">{{ FormMSG(3, 'Defraiment') }} :</div>
					&nbsp;
					<div class="font-weight-bold fs-12 mt-1">
						{{ getCurrencyRow(item, FIMALAC_PROJECT_FLAG.DEFRAYAL) }}
					</div>
				</div>
			</div>
			<div class="d-flex flex-row justify-content-center align-items-start w-100 show-hover">
				<div class="d-flex flex-row" style="width: 18%"></div>
				<div class="d-flex flex-row" style="width: 82%">
					<div class="font-weight-bold fs-12 mt-1" style="text-transform: capitalize">{{ FormMSG(4, 'Brut') }} :</div>
					&nbsp;
					<div class="font-weight-bold fs-12 mt-1">{{ getCurrencyRow(item, FIMALAC_PROJECT_FLAG.GROSS) }}</div>
				</div>
			</div>
		</div>
	</div>
	<div
		v-else-if="isGrouping && showVerified"
		id="grouping-paie"
		class="d-flex flex-column align-items-center justify-content-start default-content-paie"
		:style="{ width: `${widthCell + 60}px !important` }"
	>
		<div class="item">
			<div class="d-flex flex-row" style="width: 18%">
				<b-button
					:disabled="checkSomme(totalGrouping, FIMALAC_PROJECT_FLAG.GROSS)"
					size="sm"
					class="btn bg-transparent border-0"
					:id="`tooltip-standard`"
				>
					<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" :stroke-width="2.25" />
				</b-button>
			</div>
			<b-tooltip :target="`tooltip-standard`" placement="left" class="v-bootstrap-tooltip-custom">
				<custom-tooltip :is-row="false" :rowItem="totalGrouping" :type="FIMALAC_PROJECT_FLAG.SALARY" :isProduction="isProduction" />
			</b-tooltip>
			<div>{{ FormMSG(5645, 'Standard') }} : {{ getCurrency(totalGrouping, FIMALAC_PROJECT_FLAG.SALARY) }}</div>
		</div>
		<div class="item">
			<div class="d-flex flex-row" style="width: 18%">
				<b-button :disabled="checkSomme(totalGrouping, FIMALAC_PROJECT_FLAG.PRIME)" size="sm" class="btn bg-transparent border-0" :id="`tooltip-prime`">
					<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" :stroke-width="2.25" />
				</b-button>
			</div>
			<b-tooltip :target="`tooltip-prime`" placement="left" class="v-bootstrap-tooltip-custom">
				<custom-tooltip :is-row="false" :rowItem="totalGrouping" :type="FIMALAC_PROJECT_FLAG.PRIME" :isProduction="isProduction" />
			</b-tooltip>
			<div>{{ FormMSG(5646, 'Prime') }} : {{ getCurrency(totalGrouping, FIMALAC_PROJECT_FLAG.PRIME) }}</div>
		</div>
		<div class="item">
			<div class="d-flex flex-row" style="width: 18%">
				<b-button
					:disabled="checkSomme(totalGrouping, FIMALAC_PROJECT_FLAG.DEFRAYAL)"
					size="sm"
					class="btn bg-transparent border-0"
					:id="`tooltip-defraiment`"
				>
					<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" :stroke-width="2.25" />
				</b-button>
			</div>
			<b-tooltip :target="`tooltip-defraiment`" placement="left" class="v-bootstrap-tooltip-custom">
				<custom-tooltip :is-row="false" :rowItem="totalGrouping" :type="FIMALAC_PROJECT_FLAG.DEFRAYAL" :isProduction="isProduction" />
			</b-tooltip>
			<div>{{ FormMSG(5647, 'Defraiment') }} : {{ getCurrency(totalGrouping, FIMALAC_PROJECT_FLAG.DEFRAYAL) }}</div>
		</div>
		<div class="item">
			<div class="d-flex flex-row" style="width: 18%"></div>
			<div>{{ FormMSG(5648, 'Brut') }} : {{ getCurrencyBrut(totalGrouping, FIMALAC_PROJECT_FLAG.GROSS) }}</div>
		</div>
	</div>
</template>
<script>
import CustomTooltip from './CustomTooltip.vue';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import globalMixin from '@/mixins/global.mixin';
import { FIMALAC_PROJECT_FLAG, VALIDATION_TYPE } from '@/shared/constants';
import { rendCurrency } from '~helpers';

export default {
	name: 'CellFixedTablesPaie',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	components: { CustomTooltip },
	props: {
		widthCell: {
			type: Number,
			required: false
		},
		item: {
			type: Object,
			required: false
		},
		totalGrouping: {
			type: Array,
			required: false
		},
		isProduction: {
			type: Boolean,
			required: false
		},
		isGrouping: {
			type: Boolean,
			required: false
		},
		showVerified: {
			type: Boolean,
			required: false
		}
	},
	data: () => ({ FIMALAC_PROJECT_FLAG, rendCurrency, VALIDATION_TYPE }),
	methods: {
		checkSomme(item, type) {
			const filterType = item.filter((item) => item.type === type);
			if (filterType.length !== 0) {
				const amounts = [...filterType].map((item) => item.amount).reduce((a, b) => a + b, 0);
				if (amounts > 0) {
					return false;
				}
				return true;
			}
			return true;
		},
		getCurrencyBrut(item, isGross) {
			const { SALARY, PRIME, GROSS } = FIMALAC_PROJECT_FLAG;
			// const { SALARY, PRIME } = FLAG_TYPE;
			let hideSalary = false;
			const filterItem = (data, type) => {
				return data.filter(({ type: itemType }) => itemType === type);
			};
			const setSomme = (uniques) => {
				return uniques.map((item) => item.amount).reduce((a, b) => a + b, 0);
			};
			const checkSalary = (uniques) => {
				return uniques.map(({ hideSalary }) => hideSalary).some((isHidden) => isHidden === true);
			};
			if (!item) return this.returnTypeAmount(hideSalary, 0, isGross);
			else {
				if (isGross === GROSS) {
					let uniques = [];
					const uniqueSalaries = filterItem(item, SALARY);
					const uniquePrimes = filterItem(item, PRIME);
					uniques = [...uniqueSalaries, ...uniquePrimes];
					const amountTotal = setSomme(uniques);
					const hideSalaries = checkSalary(uniques);
					return this.returnTypeAmount(!hideSalaries, amountTotal, isGross);
				}
				return this.returnTypeAmount(!hideSalary, 0, isGross);
			}
		},
		getCurrency(item, type) {
			let hideSalary = false;
			if (!item) return this.returnTypeAmount(hideSalary, 0, type);
			else {
				const uniques = [...item].filter((item) => item.type === type);
				const amountTotal = uniques.map((item) => item.amount).reduce((a, b) => a + b, 0);
				const hideSalaries = uniques.map(({ hideSalary }) => hideSalary).some((isHidden) => isHidden === true);
				return this.returnTypeAmount(!hideSalaries, amountTotal, type);
			}
		},
		returnTypeAmount(_, amountTotal, type) {
			const { GROSS, SALARY, PRIME } = FIMALAC_PROJECT_FLAG;
			const item = this.item;
			if (item) {
				const { id: userId, managerSalaryAcess } = item;
				const isItem = managerSalaryAcess.find(({ managerID, salaryType }) => +managerID === +userId && +salaryType === +type);
				if (!this.isProduction && isItem) {
					const unite = `${rendCurrency(amountTotal, 3)}`.replaceAll('K', '').split(' ')[1];
					return `Xxx.xx ${unite}`;
				} else if (type === GROSS) {
					if (!this.isProduction) {
						const { id: userId, managerSalaryAcess } = item;
						const isSalaryAndPrimeHiddeSalary = managerSalaryAcess
							.filter(({ managerID }) => +managerID === +userId)
							.some(({ salaryType }) => salaryType === +SALARY || +salaryType === PRIME);
						const unite = `${rendCurrency(amountTotal, 3)}`.replaceAll('K', '').split(' ')[1];
						const formatX = `Xxx.xx ${unite}`;
						return isSalaryAndPrimeHiddeSalary ? formatX : `${rendCurrency(amountTotal, 3)}`;
					}
					return `${rendCurrency(amountTotal, 3)}`;
				}
				return `${rendCurrency(amountTotal, 3)}`;
			}
			return `${rendCurrency(amountTotal, 3)}`;
		},
		getCurrencyRow(item, type) {
			let hideSalary = false;
			const { SALARY, PRIME, GROSS } = FIMALAC_PROJECT_FLAG;
			const filterItem = (data, type) => {
				return data.filter(({ type: itemType }) => itemType === type);
			};
			const setSomme = (uniques) => {
				return uniques.flatMap(({ totalByCostCenter }) => totalByCostCenter).reduce((total, { amount }) => total + amount, 0) || 0;
			};
			const checkSalary = (uniques) => {
				return uniques.map(({ hideSalary }) => hideSalary).some((isHidden) => isHidden === true);
			};
			if (!item) return this.returnTypeAmount(hideSalary, 0, type);
			else {
				const tsDays = item.tsDaysTotal || [];
				if (type === GROSS) {
					let uniques = [];
					const salaries = filterItem(tsDays, SALARY);
					const primes = filterItem(tsDays, PRIME);
					uniques = [...salaries, ...primes];
					const amountTotal = setSomme(uniques);
					const hideSalaries = checkSalary(uniques); // gross somme
					return this.returnTypeAmount(!hideSalaries, amountTotal, type);
				} else {
					const uniques = filterItem(tsDays, type);
					const amountTotal = setSomme(uniques);
					const hideSalaries = checkSalary(uniques);
					return this.returnTypeAmount(!hideSalaries, amountTotal, type);
				}
			}
		}
	}
};
</script>
<style scoped lang="scss">
.show-hover:hover {
	background-color: #cad0d7;
	border-radius: 5px;
	cursor: pointer;
}
.item {
	display: flex;
	align-items: center;
	flex-direction: row;
	width: 100%;
	margin-left: 22px;
	height: 20px;
	text-transform: capitalize;
	font-weight: 700 !important;
}
.default-content-paie {
	position: absolute !important;
	border-radius: 3px !important;
	background-color: rgb(202, 208, 215) !important;
	margin: 136px 42px 41px !important;
	padding: 4px !important;
}
.gap-2 {
	gap: 2px !important;
}
</style>

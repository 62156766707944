<template>
	<section>
		<b-row class="d-flex align-items-center">
			<b-col cols="10">
				<h1 class="h-text-color">
					{{ convertTextDate(showDetailData.date) }}
				</h1>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="d-flex justify-content-start align-items-center">
				<h6 v-if="!isFilmSingle" class="h-text-color p-1">
					<span>
						{{ FormMSG(257, 'Encoded by:') }}
						<b>
							{{ encoder(showDetailData) }}
						</b>
					</span>
				</h6>
			</b-col>
			<b-col cols="6" class="d-flex justify-content-end align-items-center">
				<h6 v-if="!isFilmSingle" class="p-1">
					<div class="name-title">
						{{ getUserFullName(showDetailData) }}
						<span class="divider-dot"></span>
						{{ getUserFunctionName(showDetailData) }}
					</div>
				</h6>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="4">
				<h6 class="p-1">
					<div class="name-title">
						{{ FormMSG(13, 'Status') }}
					</div>
				</h6>
			</b-col>
			<b-col cols="8">
				<div class="wrap-status d-flex justify-content-end align-items-end p-1">
					<div :class="`status ${classStatus(showDetailData.validated)}`" style="font-size: 0.675rem">
						{{ getLabelStatus(showDetailData) }}
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row style="margin-top: 20px">
			<b-col>
				<div class="details-timesheet">
					<ul>
						<li>
							<div class="column-flag-1">{{ FormMSG(14, 'Rubric') }}</div>
							<div class="column-flag-2">{{ FormMSG(8, 'Category') }}</div>
							<div class="column-flag-3">{{ FormMSG(9, 'Activity') }}</div>
							<div class="column-flag-4">{{ FormMSG(10, 'Decors') }}</div>
							<div class="column-flag-5">{{ FormMSG(11, 'Quantity') }}</div>
							<div class="column-flag-6">{{ FormMSG(12, 'Amount') }}</div>
							<div class="column-flag-7">{{ FormMSG(15, 'Total') }}</div>
						</li>
						<li
							class="timesheet-comment border-0 rounded-0"
							v-if="
								(showDetailData.validated === 4 || showDetailData.validated === 16) &&
								showDetailData.comment &&
								showDetailData.comment.length > 0
							"
						>
							<p>{{ showDetailData.comment }}</p>
						</li>
						<li v-for="(mapInfo, keyInfo) of showDetailData.tsDayDetails" :key="keyInfo">
							<div class="column-flag-1">
								<div
									class="d-flex justify-content-center align-items-center p-1 text-black px-3 h-auto"
									:style="`background-color: #${mapInfo.projectFlagItem.color}; width: auto; border-radius: 4px;height: 20px;margin-top: 2px;`"
								>
									<span :style="`color: ${isColorLight(`#${mapInfo.projectFlagItem.color}`) ? 'black' : 'white'}; font-weight: bold`">{{
										getProjectFlagToText(mapInfo)
									}}</span>
								</div>
							</div>
							<div class="column-flag-2 mr-1 ml-2">{{ setCategory(mapInfo) }}</div>
							<div class="column-flag-3 mr-1 ml-2">{{ setActivity(mapInfo) }}</div>
							<div class="column-flag-4 mr-1 ml-2">{{ setLocationName(mapInfo) }}</div>
							<div class="column-flag-5 mr-1 ml-2">{{ getQuantity(mapInfo) }}</div>
							<div class="column-flag-6 mr-1 ml-2">{{ formatCurrency(mapInfo) }}</div>
							<div class="column-flag-7 mr-1 ml-2">{{ setTotal(mapInfo) }}</div>
						</li>
					</ul>
				</div>
			</b-col>
		</b-row>
	</section>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { rendCurrency, rendKgCo2 } from '~helpers';
import moment from 'moment';
import { classStatus } from '@/shared/global-status';
import { Clock10, Sprout, Info } from 'lucide-vue';
import * as _ from 'lodash';

export default {
	name: 'RowInfoContent',
	components: { Clock10, Sprout, Info },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		showDetailData: {
			type: Object,
			required: false,
			default: () => []
		},
		isProduction: {
			type: Boolean,
			required: false,
			default: () => false
		}
	},
	data() {
		const setWorked = (hour, minute) => {
			if (hour !== undefined && minute !== undefined) {
				return `${hour.toString().padStart('2', '0')}` + ':' + `${minute.toString().padStart('2', '0')}`;
			}
		};
		return {
			setWorked,
			isCollapsed: false,
			rendCurrency,
			rendKgCo2,
			classStatus,
			validated: null
		};
	},
	methods: {
		handleShowContent() {
			this.isCollapsed = !this.isCollapsed;
		},
		isColorLight(color) {
			const hex = color.replace('#', '');
			const red = parseInt(hex.substring(0, 0 + 2), 16);
			const green = parseInt(hex.substring(2, 2 + 2), 16);
			const blue = parseInt(hex.substring(4, 4 + 2), 16);
			const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
			return brightness > 155;
		},
		setCategory(item) {
			if (item !== undefined) {
				let category = item.costCenter;
				let shotActivity = item.activity.short;
				if (category.length === 0) {
					return 'None';
				} else {
					return category.replaceAll('$', shotActivity);
				}
			}
			return 'None';
		},
		setLocationName(item) {
			if (item !== null || item !== undefined) {
				if (+item.location.id === 0) {
					return 'Off';
				}
				return item.location.setName;
			}
			return 'None';
		},
		setActivity(item) {
			if (item !== undefined) {
				let activity = item.activity.name;
				let short = item.activity.short;
				if (activity.length !== 0) {
					if (short.length !== 0) {
						return activity + ' (' + short + ')';
					}
					return activity;
				}
				return 'None';
			}
		},
		getUserFullName(showDetailData) {
			if (showDetailData.user !== undefined) {
				return `${showDetailData.user.name} ${showDetailData.user.firstName}`;
			}
		},
		getUserFunctionName(showDetailData) {
			if (showDetailData.user !== undefined) {
				return showDetailData.user.functionName;
			}
		},
		getProjectFlagToText(item) {
			if (item.projectFlagItem !== undefined || item.projectFlagItem !== null) {
				return `${item.projectFlagItem.name} ${'(' + item.projectFlagItem.short + ')'}`;
			}
		},
		encoder(data) {
			if (data.encoder !== null) {
				let firstName = data.encoder !== undefined ? data.encoder.firstName : '';
				let name = data.encoder !== undefined ? data.encoder.name : '';
				return `${firstName} ${name}`;
			}
		},
		setTotal(item) {
			if (item !== null || item !== undefined) {
				const hideSalary = item.hideSalary;
				let total = +item.quantity * +item.amount;
				const amountTotal = rendCurrency(total);
				return this.rendAmountCurrency(hideSalary, amountTotal);
			}
			return 0;
		},
		getQuantity(item) {
			if (item) {
				const { quantity, hideSalary } = item;
				if (this.isProduction) {
					return quantity.toString().padStart('2', '0');
				} else {
					if (!hideSalary) {
						return quantity.toString().padStart('2', '0');
					}
					return `Xx`;
				}
			}
			return '00';
		},
		rendAmountCurrency(hideSalary, amount) {
			if (!hideSalary) {
				return amount;
			} else {
				if (this.isProduction) {
					return amount;
				}
				const unite = `${amount}`.replaceAll('K', '').split(' ')[1];
				return `Xx.xx ${unite}`;
			}
		},
		formatCurrency(mapInfo) {
			const { amount, hideSalary } = mapInfo;
			const _amount = rendCurrency(amount);
			return this.rendAmountCurrency(hideSalary, _amount);
		},
		convertTextDate(value) {
			const langCurrent = this.currentLang;
			let result = moment(value).utc().locale(`${langCurrent}`).format('DD MMMM YYYY');
			return result;
		},
		setTextInfoBullWorkFlowCellInfo(value) {
			if (!_.isNil(value)) {
				switch (value) {
					case 0:
						return this.FormMSG(342, 'Not submitted');
					case 1:
						return this.FormMSG(376, 'Transmitted Manager');
					case 2:
						return this.FormMSG(377, 'Validated Charged / Dir. by Prod.');
					case 8:
						return this.FormMSG(388, 'Validated Production Admin.');
					case 4:
						return this.FormMSG(340, 'Refused Production Manager');
					case 16:
						return this.FormMSG(341, 'Refused Production Admin');
					default:
						return this.FormMSG(375, 'Not found');
				}
			}
		},
		getLabelStatus(item) {
			if (!_.isNil(item)) {
				const value = +item.validated;
				if (value === this.codePaie) {
					return this.FormMSG(374, 'Validated payroll manager');
				} else {
					const text = this.setTextInfoBullWorkFlowCellInfo(value);
					return text;
				}
			}
		}
	}
};
</script>
<style lang="scss">
.h-text-color {
	color: rgba(6, 38, 62, 0.84);
}
.name-title {
	font-size: 14px;
	font-weight: 500;
	color: #06263e;
}
.column-flag-1 {
	font-size: 0.875rem !important;
	font-weight: 600;
	color: #06263e;
	width: 16%;
}
.column-flag-2,
.column-flag-3,
.column-flag-4,
.column-flag-5,
.column-flag-6 {
	font-size: 0.875rem !important;
	font-weight: 600;
	color: #06263e;
	width: 14%;
	text-align: center;
}
.column-flag-7 {
	font-weight: 600;
	font-size: 0.875rem !important;
	color: #06263e;
	width: 14%;
	text-align: right;
}
.set-border-info-cell {
	background-color: #f5f7f9;
	padding: 16px;
	border-radius: 8px;
}
.divider {
	display: flex;
	align-items: center;
	justify-content: center;
}
.border-item {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10px;
	width: 100%;
	border-bottom: 4px solid #e2e6eb;
}
</style>

<template>
	<div class="d-flex flex-row justify-content-start align-items-center">
		<div class="d-flex flex-column justify-content-start p-1" style="width: 100%; height: 100px">
			<div class="font-weight-bold fs-14">{{ item.fullName }}</div>
			<div>{{ item.functionName }}</div>
		</div>
		<div v-if="isProduction" class="d-flex flex-column justify-content-start align-items-center pd-1">
			<div class="d-flex flex-row w-100 show-hover">
				<b-button
					:disabled="isCheckValidatedAll && isHaveSolved"
					@click="handleValidateAllTsDays()"
					size="sm"
					class="btn bg-transparent border-0"
					v-b-tooltip.hover.bottom.html="`${handleShowTooltip}`"
				>
					<component
						:is="getLucideIcon(ICONS.CHECK_SQUARE.name)"
						:color="!isHaveSolved ? '#EA4E2C' : ICONS.CHECK_SQUARE.color"
						:size="16"
						:stroke-width="2.25"
					/>
				</b-button>
			</div>
		</div>
		<div v-if="isProduction === false" class="d-flex flex-column justify-content-start align-items-center pd-1">
			<div class="d-flex flex-row w-100 show-hover">
				<b-button
					:disabled="isCheckNotSubmitted"
					@click="handleSendAllForValidation()"
					size="sm"
					class="btn bg-transparent border-0"
					v-b-tooltip.hover.bottom.html="`${FormMSG(4, 'Send all for validation')}`"
				>
					<component
						:is="getLucideIcon(ICONS.SEND.name)"
						:color="isCheckNotSubmitted ? ICONS.SEND.color : '#EA4E2C'"
						:size="16"
						:stroke-width="2.25"
					/>
				</b-button>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'CellFixedTablesMember',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	data: () => ({}),
	props: {
		item: {
			type: Object,
			required: false,
			default: () => ({})
		},
		isCheckValidatedAll: {
			type: Boolean,
			required: false,
			default: () => false
		},
		isHaveSolved: {
			type: Boolean,
			required: false
		},
		isProduction: {
			type: Boolean,
			required: false,
			default: () => false
		},
		onValidateAllItem: {
			type: Function,
			required: false
		},
		onSendAllForValidation: {
			type: Function,
			required: false
		},
		isCheckNotSubmitted: {
			type: Boolean,
			required: false,
			default: () => false
		}
	},
	computed: {
		handleShowTooltip() {
			return !this.isHaveSolved
				? this.isCheckValidatedAll
					? `<div>${this.FormMSG(1, 'There is one or more timesheets to resolve')}</div>`
					: `<div>${this.FormMSG(2, 'On this line, there are one or more timesheets to send to the validation or to resolve')}</div>`
				: this.FormMSG(3, 'Valid all');
		}
	},
	watch: {},
	methods: {
		handleValidateAllTsDays() {
			this.onValidateAllItem(this.item);
		},
		handleSendAllForValidation() {
			this.onSendAllForValidation(this.item);
		}
	}
};
</script>
<style scoped lang="scss">
.show-hover:hover {
	background-color: #cad0d7;
	border-radius: 5px;
	cursor: pointer;
}
</style>

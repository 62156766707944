<template>
	<section class="d-flex flex-column align-items-center justify-content-center w-100" v-click-outside="onOutsideClickPopover">
		<div class="d-flex flex-row gap-1 w-100 p-1">
			<div>
				<b-form-checkbox
					:id="`checkbox-all`"
					v-model="checkedAll"
					:name="`checkbox-all`"
					:value="true"
					:unchecked-value="false"
					switch
					@change="(e) => handleChangeSelectOptionAll(e)"
				/>
			</div>
			<div class="fs-14 fw-500">{{ FormMSG(1, 'check all') }}</div>
		</div>
		<div class="d-flex flex-column w-100 p-1 limited-item">
			<div class="d-flex flex-row gap-1 w-100" v-for="(item, index) of mapData(optionColumnConfig)" :key="index">
				<div>
					<b-form-checkbox
						:id="`checkbox-${index}`"
						v-model="item.checked"
						:name="`checkbox-${index}`"
						:value="true"
						:unchecked-value="false"
						@change="(e) => handleChangeOption(e, item)"
					/>
				</div>
				<div class="fs-14 fw-500">{{ item.date }}</div>
			</div>
		</div>
		<div class="d-flex flex-row w-100 p-1 gap-1">
			<div class="d-flex flex-row justify-content-start flex-grow-1">
				<button class="custom-btn border-custom-close" @click="handleClose">
					<component :is="getLucideIcon(ICONS.X_CLEAR.name)" :color="ICONS.X_CLEAR.color" :size="20" />
				</button>
			</div>
			<div class="d-flex flex-row justify-content-end flex-grow-1">
				<button class="custom-btn border-custom-success" :disabled="checkActive" @click="handleSubmitted">
					<component :is="getLucideIcon(ICONS.CHECK_CIRCLE.name)" color="#47C7BF" :size="20" />
				</button>
			</div>
		</div>
	</section>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
export default {
	name: 'ListHiddenColumn',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	data: () => ({
		status: false,
		checkedAll: false,
		data: [],
		optionColumnConfig: null
	}),
	props: {},
	computed: {
		checkActive() {
			const check = this.data.some((item) => item.checked);
			return !check;
		}
	},
	methods: {
		mapData(item) {
			if (item) {
				return item.data;
			}
			return [];
		},
		handleChangeSelectOptionAll(event) {
			const generatedEvent = (e) => {
				if (this.optionColumnConfig) {
					const { data } = this.optionColumnConfig;
					const res = data.map((item) => ({ ...item, checked: e }));
					this.optionColumnConfig = { data: res };
					const { data: newData } = this.optionColumnConfig;
					this.data = newData;
					if (!e) {
						this.data = [];
					}
				}
			};
			generatedEvent(event);
		},
		handleChangeOption(event, item) {
			const date = item.date;
			if (event) {
				this.data = [...this.data, { ...item, checked: event }];
			} else {
				this.data = this.data.filter((item) => item.date !== date);
			}
		},
		handleSubmitted() {
			this.$emit('type:submitted', { data: this.data });
		},
		handleClose() {
			this.$emit('type:close');
		},
		onOutsideClickPopover() {
			this.$emit('popover:close-all');
		}
	}
};
</script>
<style lang="scss" scoped>
.gap-1 {
	gap: 1px;
}
.custom-btn {
	border: none;
	border-radius: 4px;
}
.border-custom-close {
	&:hover {
		border: 1px solid #ea4e2c;
	}
}
.border-custom-success {
	&:hover {
		border: 1px solid #47c7bf;
	}
	&:disabled {
		background-color: #f3f4f5;
		color: white;
		cursor: not-allowed;
		opacity: 0.8;
		&:hover {
			border: none;
		}
	}
}
.limited-item {
	max-height: 120px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 8px;
		background: #eee;
	}
	&::-webkit-scrollbar-thumb {
		background: #333;
	}
}
</style>

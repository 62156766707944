var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "quick-modal-addition",
      attrs: {
        title: _vm.FormMSG(59, "Quick addition"),
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "dialog-class": "dialog-custom",
      },
      on: {
        cancel: _vm.emitEventClose,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: cancel },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(43, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "md",
                        variant: "primary",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: ok },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _vm.loadingSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.loadingSubmit ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(42, "Save")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _vm.isAuth
        ? _c(
            "b-row",
            { staticClass: "d-flex align-items-center mb-3" },
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex text-color-rhapsody-in-blue" },
                  [
                    _c("div", { staticClass: "fs-16 fw-700" }, [
                      _vm._v(_vm._s(_vm.FormMSG(541, "Category : "))),
                    ]),
                    _c("div", { staticClass: "fs-16 ml-3" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.getCategory(_vm.categorySelected)) +
                          "\n\t\t\t\t"
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("b-col", { attrs: { cols: "4" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex text-color-rhapsody-in-blue" },
                  [
                    _c("div", { staticClass: "fs-16 fw-700" }, [
                      _vm._v(_vm._s(_vm.FormMSG(581, "Activity : "))),
                    ]),
                    _c("div", { staticClass: "fs-16 ml-3" }, [
                      _vm._v(_vm._s(_vm.getActivity(_vm.activitySelected))),
                    ]),
                  ]
                ),
              ]),
              _vm.isShowDecors
                ? _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "b-collapse",
                        { attrs: { visible: _vm.addDecor === false } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(215, "Decors") } },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-select", {
                                    staticClass: "grow-flex-auto",
                                    attrs: {
                                      id: "supplier-selector",
                                      options: _vm.locationList,
                                      "close-on-select": "",
                                      label: "setName",
                                      reduce: (option) => option.id,
                                      placeholder: _vm.FormMSG(
                                        452,
                                        "Select a decor"
                                      ),
                                      clearable: false,
                                    },
                                    on: {
                                      "option:selecting": _vm.handleSetLocation,
                                    },
                                    model: {
                                      value: _vm.locationIdSelected,
                                      callback: function ($$v) {
                                        _vm.locationIdSelected = $$v
                                      },
                                      expression: "locationIdSelected",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row icon-group-border-append",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              modifiers: {
                                                hover: true,
                                                top: true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "cursor-pointer d-inline-flex justify-content-center align-items-center",
                                          style: `width: 34px`,
                                          attrs: {
                                            title: _vm.FormMSG(
                                              157,
                                              "Add new decor"
                                            ),
                                          },
                                          on: {
                                            click: _vm.handleClickAddNewDecor,
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("PlusCircle"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#00A09C",
                                              size: 18,
                                              "stroke-width": 2.25,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-collapse",
                        { attrs: { visible: _vm.addDecor === true } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(198, "Name of decor"),
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-row w-100" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-grow-1 flex-column",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-row" },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: _vm.FormMSG(
                                                199,
                                                "Enter name"
                                              ),
                                            },
                                            model: {
                                              value: _vm.decorName,
                                              callback: function ($$v) {
                                                _vm.decorName = $$v
                                              },
                                              expression: "decorName",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row flex-grow-0",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "cursor-pointer d-inline-flex justify-content-center align-items-center",
                                                  style: `width: 34px`,
                                                  attrs: {
                                                    title: _vm.FormMSG(
                                                      137,
                                                      "Add from list"
                                                    ),
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickAddNewDecor,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon("Undo2"),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#00A09C",
                                                        size: 20,
                                                        "stroke-width": 2.25,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "cursor-pointer d-inline-flex justify-content-center align-items-center",
                                                  staticStyle: {
                                                    border: "1px solid #00a09c",
                                                    "border-radius": "4px",
                                                  },
                                                  style: `width: 34px`,
                                                  attrs: {
                                                    variant: "none",
                                                    title: _vm.FormMSG(
                                                      138,
                                                      "Send to list of decors"
                                                    ),
                                                    disabled: _vm.sendAction,
                                                  },
                                                  on: {
                                                    click: _vm.handleClickSave,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon("Send"),
                                                    {
                                                      tag: "component",
                                                      staticStyle: {
                                                        transform:
                                                          "rotate(40deg)",
                                                      },
                                                      attrs: {
                                                        color: "#00A09C",
                                                        size: 20,
                                                        "stroke-width": 2.25,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.decorName.length === 0
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      487,
                                                      "Decor name is required"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isAuth === false
        ? _c(
            "b-row",
            { staticClass: "d-flex align-items-center mb-3" },
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex text-color-rhapsody-in-blue" },
                  [
                    _c("div", { staticClass: "fs-16 fw-700" }, [
                      _vm._v(_vm._s(_vm.FormMSG(541, "Category : "))),
                    ]),
                    _c("div", { staticClass: "fs-16 ml-3" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.getCategory(_vm.categorySelected)) +
                          "\n\t\t\t\t"
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("b-col", { attrs: { cols: "4" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex text-color-rhapsody-in-blue" },
                  [
                    _c("div", { staticClass: "fs-16 fw-700" }, [
                      _vm._v(_vm._s(_vm.FormMSG(581, "Activity : "))),
                    ]),
                    _c("div", { staticClass: "fs-16 ml-3" }, [
                      _vm._v(_vm._s(_vm.getActivity(_vm.activitySelected))),
                    ]),
                  ]
                ),
              ]),
              _vm.isShowDecors
                ? _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "b-collapse",
                        { attrs: { visible: _vm.addDecor === false } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(215, "Decors") } },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-select", {
                                    staticClass: "grow-flex-auto",
                                    attrs: {
                                      id: "supplier-selector",
                                      options: _vm.locationList,
                                      "close-on-select": "",
                                      label: "setName",
                                      reduce: (option) => option.id,
                                      placeholder: _vm.FormMSG(
                                        452,
                                        "Select a decor"
                                      ),
                                      clearable: false,
                                    },
                                    on: {
                                      "option:selecting": _vm.handleSetLocation,
                                    },
                                    model: {
                                      value: _vm.locationIdSelected,
                                      callback: function ($$v) {
                                        _vm.locationIdSelected = $$v
                                      },
                                      expression: "locationIdSelected",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row icon-group-border-append",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              modifiers: {
                                                hover: true,
                                                top: true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "cursor-pointer d-inline-flex justify-content-center align-items-center",
                                          style: `width: 34px`,
                                          attrs: {
                                            title: _vm.FormMSG(
                                              157,
                                              "Add new decor"
                                            ),
                                          },
                                          on: {
                                            click: _vm.handleClickAddNewDecor,
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("PlusCircle"), {
                                            tag: "component",
                                            attrs: {
                                              color: "#00A09C",
                                              size: 18,
                                              "stroke-width": 2.25,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-collapse",
                        { attrs: { visible: _vm.addDecor === true } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(198, "Name of decor"),
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-row w-100" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-grow-1 flex-column",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-row" },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: _vm.FormMSG(
                                                199,
                                                "Enter name"
                                              ),
                                            },
                                            model: {
                                              value: _vm.decorName,
                                              callback: function ($$v) {
                                                _vm.decorName = $$v
                                              },
                                              expression: "decorName",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row flex-grow-0",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "cursor-pointer d-inline-flex justify-content-center align-items-center",
                                                  style: `width: 34px`,
                                                  attrs: {
                                                    title: _vm.FormMSG(
                                                      137,
                                                      "Add from list"
                                                    ),
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickAddNewDecor,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon("Undo2"),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color: "#00A09C",
                                                        size: 20,
                                                        "stroke-width": 2.25,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                      },
                                                    },
                                                  ],
                                                  staticClass:
                                                    "cursor-pointer d-inline-flex justify-content-center align-items-center",
                                                  staticStyle: {
                                                    border: "1px solid #00a09c",
                                                    "border-radius": "4px",
                                                  },
                                                  style: `width: 34px`,
                                                  attrs: {
                                                    variant: "none",
                                                    title: _vm.FormMSG(
                                                      138,
                                                      "Send to list of decors"
                                                    ),
                                                    disabled: _vm.sendAction,
                                                  },
                                                  on: {
                                                    click: _vm.handleClickSave,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon("Send"),
                                                    {
                                                      tag: "component",
                                                      staticStyle: {
                                                        transform:
                                                          "rotate(40deg)",
                                                      },
                                                      attrs: {
                                                        color: "#00A09C",
                                                        size: 20,
                                                        "stroke-width": 2.25,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.decorName.length === 0
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      487,
                                                      "Decor name is required"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.$screen.width >= 992
                ? _c("b-table", {
                    ref: "documentPackageTable",
                    staticStyle: { "text-align": "left" },
                    attrs: {
                      "selected-variant": "primary",
                      hover: "",
                      selectable: "",
                      "select-mode": "single",
                      responsive: "sm",
                      "sticky-header": "500px",
                      items: _vm.dataList,
                      fields: _vm.tableFields,
                      bordered: "",
                      striped: "",
                      small: "",
                      "head-variant": "dark",
                      "empty-text": _vm.FormMSG(250, "No data found"),
                      "tbody-tr-class": "row-class",
                      "show-empty": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(amount)",
                          fn: function ({ item, index }) {
                            return [
                              item.isAmountHidden
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row align-items-center justify-content-end gap-1",
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.getCurrency(item))),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm.$v.dataList.$each.$iter[index]
                                        ? _c(
                                            "b-form-group",
                                            { staticClass: "mb-0" },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      state:
                                                        !_vm.$v.dataList.$each
                                                          .$iter[index].amount
                                                          .$error && _vm.isAuth
                                                          ? true
                                                          : "",
                                                    },
                                                    on: {
                                                      input: (value) =>
                                                        _vm.handleChangeAmount(
                                                          value,
                                                          index
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.dataList.$each
                                                          .$iter[index].amount
                                                          .$model,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.$v.dataList.$each
                                                            .$iter[index]
                                                            .amount,
                                                          "$model",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.dataList.$each.$iter[index].amount.$model",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        [
                                                          _c("currency-svg", {
                                                            attrs: {
                                                              color: "#06263E",
                                                              opacity: "0.85",
                                                              width: "15",
                                                              height: "15",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.isAuth !== false &&
                                              _vm.$v.dataList.$each.$iter[index]
                                                .amount.$invalid
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              181,
                                                              "Amount is required or must greater 0"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                            ]
                          },
                        },
                        {
                          key: "cell(quantity)",
                          fn: function ({ item, index }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row align-items-center justify-content-center",
                                },
                                [
                                  item.isAmountHidden
                                    ? _c("div", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.getQuantity(item)) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "b-form-group",
                                            { staticClass: "mb-0" },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "number",
                                                      min: 1,
                                                      step: 1,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.dataList.$each
                                                          .$iter[index].$model
                                                          .quantity,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.$v.dataList.$each
                                                            .$iter[index]
                                                            .$model,
                                                          "quantity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.dataList.$each.$iter[index].$model.quantity",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2280628813
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-danger",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(585, "Information"),
            "no-close-on-backdrop": "",
            "ok-variant": "danger",
            "ok-only": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row justify-content-end w-100 gap-1 w-1",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "border-1",
                              attrs: {
                                variant: "custom-outline-gray",
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddActionRegissor(_vm.cancel)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(589, "Cancel")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "border-1",
                              attrs: { variant: "outline-primary", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddActionRegissor(
                                    _vm.replace
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(588, "Replace")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "border-1",
                              attrs: { variant: "primary", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddActionRegissor(_vm.add)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(587, "Add")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.openModalItemRegissorExist,
            callback: function ($$v) {
              _vm.openModalItemRegissorExist = $$v
            },
            expression: "openModalItemRegissorExist",
          },
        },
        [
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.titleInfoActionRegissor) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(86, "Replacement"),
        "header-class": "header-class-modal-doc-package",
        size: "md",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-variant": "primary",
        "ok-disabled": _vm.loadingSubmit,
        "cancel-title": _vm.FormMSG(79, "Cancel"),
        "cancel-variant": "outline-primary",
        "cancel-disabled": _vm.loadingSubmit,
      },
      on: {
        cancel: _vm.emitEventClose,
        hidden: _vm.emitEventClose,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-ok",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _vm.loadingSubmit
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.FormMSG(78, "Validate replacement"))),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "form" },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-3 border-groove-blue-streak",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-blue-streak",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(132, "Person to replace")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-3" },
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _c("div", { staticClass: "fs-14 fw-700" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.getFullName()) +
                                    " " +
                                    _vm._s(_vm.formatedDate()) +
                                    "\n\t\t\t\t\t\t\t\t\t"
                                ),
                                _vm.getLocation().id > 0
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.getLocation().text)),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 mb-2" },
            [
              _c("b-col", [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        133,
                        "Select the replacement person from the list. All data for the day will be copied to the same day for this person."
                      )
                    ) +
                    "\n\t\t\t"
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("v-select", {
                    class: {
                      "is-invalid": _vm.$v.userReplacedSelected.$invalid,
                    },
                    attrs: {
                      options: _vm.users,
                      placeholder: _vm.FormMSG(49, "Select a person"),
                      clearable: false,
                      label: "fullName",
                    },
                    model: {
                      value: _vm.$v.userReplacedSelected.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.userReplacedSelected, "$model", $$v)
                      },
                      expression: "$v.userReplacedSelected.$model",
                    },
                  }),
                  _vm.$v.userReplacedSelected.$invalid
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(69, "User is required")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
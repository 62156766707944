<template>
	<div
		:class="{
			'd-flex flex-row justify-content-center align-items-start': true,
			'set-border-info': !isMultiple
		}"
	>
		<b-col v-if="!isMultiple" class="p-0">
			<row-info-content :showDetailData="showDetailData" :isProduction="isProduction" />
		</b-col>
		<b-col v-else class="p-0 content-row-info">
			<section v-for="(item, index) of mapShowDetailDataMultiple" @key="index">
				<multiple-row-info-content :ref="`row-info-${index}`" :item="item" :index="index" :isMultiple="isMultiple" :isProduction="isProduction" />
			</section>
		</b-col>
	</div>
</template>

<script>
import RowInfoContent from './RowInfoContent.vue';
import MultipleRowInfoContent from './MultipleRowInfoContent.vue';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import * as _ from 'lodash';

export default {
	name: 'ShowDetailPlanningCellFimalac',
	components: { MultipleRowInfoContent, RowInfoContent },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		isMultiple: {
			type: Boolean,
			required: false,
			default: () => false
		},
		isProduction: {
			type: Boolean,
			required: false,
			default: () => false
		},
		showDetailDataMultiple: {
			type: Array,
			required: false,
			default: () => []
		},
		showDetailData: {
			type: Object,
			required: false
		}
	},
	watch: {
		showDetailDataMultiple: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	data() {
		return {};
	},
	computed: {
		mapInfo() {
			if (this.showDetailData.tsDayDetails !== undefined && this.showDetailData.tsDayDetails.length !== 0) {
				let result = this.showDetailData.tsDayDetails;
				return result;
			}
			return [];
		},
		mapShowDetailDataMultiple() {
			const data = this.showDetailDataMultiple;
			const response = this.generatedArrayGrouping(data);
			return response;
		}
	},
	created() {},
	methods: {
		generatedArrayGrouping(data) {
			const filterItem = data
				.filter((item) => item.tsDay !== undefined)
				.map((item) => ({
					date: item.date,
					userId: item.userId,
					fullName: item.fullName,
					functionName: item.functionName,
					tsDay: item.tsDay
				}));
			const response = filterItem.reduce((accumulator, current) => {
				let existingItem = accumulator.find(
					(item) => item.userId === current.userId && item.fullName === current.fullName && item.functionName === current.functionName
				);
				if (existingItem) {
					existingItem.data.push(current.tsDay);
				} else {
					accumulator.push({
						data: [current.tsDay],
						userId: current.userId,
						fullName: current.fullName,
						functionName: current.functionName,
						date: current.date
					});
				}
				return accumulator;
			}, []);
			return response;
		}
	}
};
</script>
<style lang="scss" scoped>
.content-row-info {
	display: flex;
	justify-content: start;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 10px;
}
.set-border-info {
	background-color: #f5f7f9;
	padding: 16px;
	border-radius: 8px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(47, "Preferences"),
        "header-class": "header-class-modal-doc-package",
        size: "xxl",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: {
        cancel: _vm.handleCancel,
        hidden: _vm.handleCancel,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOk.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-50 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: cancel },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(43, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "md",
                        variant: "primary",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: ok },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _vm.loadingSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.loadingSubmit ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(42, "Save")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            { staticClass: "text-center" },
            [
              _c(
                "b-button-group",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: `${!_vm.fromRubric ? "" : "outline-"}primary`,
                      },
                      on: { click: _vm.toggleFromRubric },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(27, "User list")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: `${_vm.fromRubric ? "" : "outline-"}primary`,
                      },
                      on: { click: _vm.toggleFromRubric },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(28, "Favorite rubric")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "min-height": "150px" } },
        [
          _c("b-collapse", { attrs: { visible: _vm.fromRubric === false } }, [
            _vm.fromRubric === false
              ? _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.FormMSG(
                                      65,
                                      "Type to search"
                                    ),
                                  },
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.handleKeyUpEnter.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.filter,
                                    callback: function ($$v) {
                                      _vm.filter = $$v
                                    },
                                    expression: "filter",
                                  },
                                }),
                                _c(
                                  "b-input-group-append",
                                  { staticClass: "cursor-pointer" },
                                  [
                                    _c(
                                      "b-input-group-text",
                                      { staticClass: "btn-search" },
                                      [
                                        _vm.filter.length === 0
                                          ? _c(_vm.getLucideIcon("Search"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#FFFFFF",
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                            })
                                          : _c(_vm.getLucideIcon("X"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#FFFFFF",
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                              on: { click: _vm.resetFilter },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            ref: "containerPreferencesTable",
                            attrs: { id: "containerPreferencesTable" },
                          },
                          [
                            _vm.$screen.width >= 992
                              ? _c("b-table", {
                                  ref: "preferencesTable",
                                  staticStyle: { "text-align": "left" },
                                  attrs: {
                                    "selected-variant": "primary",
                                    hover: "",
                                    selectable: "",
                                    "select-mode": "single",
                                    responsive: "sm",
                                    id: "preferencesTable",
                                    "sticky-header": "500px",
                                    items: _vm.mapList,
                                    fields: _vm.tableFields,
                                    bordered: "",
                                    striped: "",
                                    small: "",
                                    "head-variant": "dark",
                                    "empty-text": _vm.FormMSG(
                                      250,
                                      "No data found"
                                    ),
                                    "show-empty": "",
                                    busy: _vm.loadingSubmit,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "table-busy",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c("b-spinner", {
                                                  attrs: { small: "" },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        815,
                                                        "Loading..."
                                                      )
                                                    )
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "cell(showing)",
                                        fn: function ({ item, index }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top.html",
                                                    value:
                                                      _vm.cannotBeDeleted(
                                                        item
                                                      ) &&
                                                      _vm.handleHoverToolTip,
                                                    expression:
                                                      "cannotBeDeleted(item) && handleHoverToolTip",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      html: true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "checkbox-switch",
                                              },
                                              [
                                                _c("b-form-checkbox", {
                                                  class: {
                                                    "disabled-checkbox":
                                                      _vm.isDisabledSalary,
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      _vm.cannotBeDeleted(item),
                                                    switch: "",
                                                    size: "lg",
                                                    value: true,
                                                    "unchecked-value": false,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleChangeShowing(
                                                        $event,
                                                        index,
                                                        "isUserVisible"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.isUserVisible,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isUserVisible",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isUserVisible",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(showSalary)",
                                        fn: function ({ item, index }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "checkbox-switch",
                                              },
                                              [
                                                _c("b-form-checkbox", {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top.html",
                                                      value:
                                                        !_vm.isDisabledSalary &&
                                                        "Show salary",
                                                      expression:
                                                        "!isDisabledSalary && 'Show salary'",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                        html: true,
                                                      },
                                                    },
                                                  ],
                                                  class: {
                                                    "disabled-checkbox":
                                                      _vm.isDisabledSalary,
                                                  },
                                                  attrs: {
                                                    id: `showSalary-${index}`,
                                                    switch: "",
                                                    size: "lg",
                                                    disabled:
                                                      _vm.isDisabledSalary,
                                                    value: true,
                                                    "unchecked-value": false,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleChangeShowing(
                                                        $event,
                                                        index,
                                                        "showSalary"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.showSalary,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "showSalary",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.showSalary",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(ShowPrime)",
                                        fn: function ({ item, index }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "checkbox-switch",
                                              },
                                              [
                                                _c("b-form-checkbox", {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top.html",
                                                      value:
                                                        !_vm.isDisabledSalary &&
                                                        "Show prime",
                                                      expression:
                                                        "!isDisabledSalary && 'Show prime'",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                        html: true,
                                                      },
                                                    },
                                                  ],
                                                  class: {
                                                    "disabled-checkbox":
                                                      _vm.isDisabledSalary,
                                                  },
                                                  attrs: {
                                                    id: `showPrime-${index}`,
                                                    switch: "",
                                                    size: "lg",
                                                    disabled:
                                                      _vm.isDisabledSalary,
                                                    value: true,
                                                    "unchecked-value": false,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleChangeShowing(
                                                        $event,
                                                        index,
                                                        "showPrime"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.showPrime,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "showPrime",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.showPrime",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(showDefrayal)",
                                        fn: function ({ item, index }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "checkbox-switch",
                                              },
                                              [
                                                _c("b-form-checkbox", {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.top.html",
                                                      value:
                                                        !_vm.isDisabledSalary &&
                                                        "Show defrayal",
                                                      expression:
                                                        "!isDisabledSalary && 'Show defrayal'",
                                                      modifiers: {
                                                        hover: true,
                                                        top: true,
                                                        html: true,
                                                      },
                                                    },
                                                  ],
                                                  class: {
                                                    "disabled-checkbox":
                                                      _vm.isDisabledSalary,
                                                  },
                                                  attrs: {
                                                    id: `showDefrayal-${index}`,
                                                    switch: "",
                                                    size: "lg",
                                                    disabled:
                                                      _vm.isDisabledSalary,
                                                    value: true,
                                                    "unchecked-value": false,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleChangeShowing(
                                                        $event,
                                                        index,
                                                        "showDefrayal"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.showDefrayal,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "showDefrayal",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.showDefrayal",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(delete)",
                                        fn: function ({ item, index }) {
                                          return [
                                            _c("div", {
                                              staticClass: "d-flex flex-row",
                                              staticStyle: { gap: "4px" },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top.html",
                                                    value:
                                                      _vm.cannotBeDeleted(
                                                        item
                                                      ) &&
                                                      _vm.handleHoverToolTip,
                                                    expression:
                                                      "cannotBeDeleted(item) && handleHoverToolTip",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                      html: true,
                                                    },
                                                  },
                                                ],
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover.left.html",
                                                        value: `Delete user`,
                                                        expression:
                                                          "`Delete user`",
                                                        modifiers: {
                                                          hover: true,
                                                          left: true,
                                                          html: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn bg-transparent border-0",
                                                    attrs: {
                                                      disabled:
                                                        _vm.cannotBeDeleted(
                                                          item
                                                        ),
                                                      variant: "primary",
                                                      size: "sm",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDeleteRow(
                                                          item,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.TRASH.name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.ICONS.TRASH
                                                              .color,
                                                          size: 20,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2918727671
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("b-collapse", { attrs: { visible: _vm.fromRubric === true } }, [
            _vm.fromRubric === true
              ? _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.FormMSG(
                                      65,
                                      "Type to search"
                                    ),
                                  },
                                  model: {
                                    value: _vm.filterRubric,
                                    callback: function ($$v) {
                                      _vm.filterRubric = $$v
                                    },
                                    expression: "filterRubric",
                                  },
                                }),
                                _c(
                                  "b-input-group-append",
                                  { staticClass: "cursor-pointer" },
                                  [
                                    _c(
                                      "b-input-group-text",
                                      { staticClass: "btn-search" },
                                      [
                                        _vm.filterRubric.length === 0
                                          ? _c(_vm.getLucideIcon("Search"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#FFFFFF",
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                            })
                                          : _c(_vm.getLucideIcon("X"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#FFFFFF",
                                                size: 16,
                                                "stroke-width": 2.5,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.filterRubric = ""
                                                },
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.$screen.width >= 992
                              ? _c("b-table", {
                                  ref: "documentPackageTable",
                                  staticStyle: { "text-align": "left" },
                                  attrs: {
                                    "selected-variant": "primary",
                                    hover: "",
                                    selectable: "",
                                    "select-mode": "single",
                                    responsive: "sm",
                                    "sticky-header": "500px",
                                    items: _vm.flagFavorites,
                                    fields: _vm.tableFieldsRubric,
                                    bordered: "",
                                    striped: "",
                                    small: "",
                                    "head-variant": "dark",
                                    "empty-text": _vm.FormMSG(
                                      250,
                                      "No data found"
                                    ),
                                    "show-empty": "",
                                    filter: _vm.filterRubric,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "cell(bookmark)",
                                        fn: function ({ item, index }) {
                                          return [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "custom-transparent",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleClickFavorite(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(_vm.getLucideIcon("Star"), {
                                                  tag: "component",
                                                  attrs: {
                                                    size: 20,
                                                    color: "#06263e",
                                                    fill: `${
                                                      item.bookmark === true
                                                        ? "#cf960b"
                                                        : "white"
                                                    }`,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3342828203
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
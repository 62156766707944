var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "d-flex flex-row justify-content-center align-items-start": true,
        "set-border-info": !_vm.isMultiple,
      },
    },
    [
      !_vm.isMultiple
        ? _c(
            "b-col",
            { staticClass: "p-0" },
            [
              _c("row-info-content", {
                attrs: {
                  showDetailData: _vm.showDetailData,
                  isProduction: _vm.isProduction,
                },
              }),
            ],
            1
          )
        : _c(
            "b-col",
            { staticClass: "p-0 content-row-info" },
            _vm._l(_vm.mapShowDetailDataMultiple, function (item, index) {
              return _c(
                "section",
                { on: { key: index } },
                [
                  _c("multiple-row-info-content", {
                    ref: `row-info-${index}`,
                    refInFor: true,
                    attrs: {
                      item: item,
                      index: index,
                      isMultiple: _vm.isMultiple,
                      isProduction: _vm.isProduction,
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
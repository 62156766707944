<style></style>
<template>
	<div class="d-flex justify-content-center flex-grow-1">
		<b-form-checkbox size="lg" v-model="selected" :value="true" @change="handleAddFlag" :key="option.id" :aria-describedby="option.name">
			<span
				class="d-flex justify-content-center align-items-center p-1 text-black px-3"
				:style="`background-color: ${setBackGround(option.code)}; 
				color:${isColorLight(option.code) ? 'black' : 'white'}; 
				border-radius: 4px;height: 20px;
    			margin-top: 2px; cursor: pointer`"
				v-b-tooltip.hover.bottom.html="showToolTipFlagItem(option)"
			>
				{{ setText(option.text) }}
			</span>
		</b-form-checkbox>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import _ from 'lodash';
export default {
	name: 'FlagItems',
	components: {},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		option: {
			type: Object,
			required: false
		},
		data: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data: () => {
		return {
			selected: null
		};
	},
	mounted() {},
	destroyed() {},

	watch: {},
	created() {},
	computed: {},
	methods: {
		showToolTipFlagItem(option) {
			const text = option.name;
			if (text) {
				return text;
			}
			return this.FormMSG(1, 'None');
		},
		setText(text) {
			if (!text) {
				return this.FormMSG(1, 'None');
			}
			return text;
		},
		setBackGround(code) {
			let _code = code.substr(1);
			if (_code) {
				return code;
			}
			return '#34eb37';
		},
		handleAddFlag(e) {
			let flag = {
				isCheck: e,
				...this.option
			};
			this.$emit('setFlags', flag);
		},
		isColorLight(color) {
			const hex = color.replace('#', '');
			if (hex) {
				const red = parseInt(hex.substring(0, 0 + 2), 16);
				const green = parseInt(hex.substring(2, 2 + 2), 16);
				const blue = parseInt(hex.substring(4, 4 + 2), 16);
				const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
				return brightness > 155;
			}
			return true;
		}
	}
};
</script>
<style>
.custom-checkbox.b-custom-control-lg .custom-control-label::after,
.input-group-lg .custom-checkbox .custom-control-label::after {
	top: 0.3125rem;
	left: -1.875rem;
	width: 1.25rem;
	height: 1.25rem;
	background-size: 50% 50%;
	cursor: pointer !important;
}
</style>

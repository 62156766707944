var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(173, "Manage users's roles"),
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.onSubmitted.apply(null, arguments)
        },
        show: _vm.handleShow,
        cancel: _vm.emitEventClose,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: cancel },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(43, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "md",
                        variant: "primary",
                        disabled: _vm.checkForm,
                        block: "",
                      },
                      on: { click: ok },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _vm.loadingSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.loadingSubmit ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(42, "Save")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "min-height": "150px" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "filterInput-1",
                          type: "text",
                          placeholder: _vm.FormMSG(65, "Type to search"),
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleKeyUpEnter.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        { staticClass: "cursor-pointer" },
                        [
                          _c(
                            "b-input-group-text",
                            { staticClass: "btn-search" },
                            [
                              _vm.filter.length === 0
                                ? _c(_vm.getLucideIcon("Search"), {
                                    tag: "component",
                                    attrs: {
                                      color: "#FFFFFF",
                                      size: 16,
                                      "stroke-width": 2.5,
                                    },
                                  })
                                : _c(_vm.getLucideIcon("X"), {
                                    tag: "component",
                                    attrs: {
                                      color: "#FFFFFF",
                                      size: 16,
                                      "stroke-width": 2.5,
                                    },
                                    on: { click: _vm.resetFilter },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: "filterInput-1",
                        placement: "top",
                        triggers: "focus",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            this.FormMSG(
                              1,
                              "You must type more than 3 characters followed by enter to activate the search"
                            )
                          ) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  ref: "containerManageUsersRoles",
                  attrs: { id: "containerManageUsersRoles" },
                },
                [
                  _vm.$screen.width >= 992
                    ? _c("b-table", {
                        ref: "preferencesTable",
                        staticStyle: { "text-align": "left" },
                        attrs: {
                          "selected-variant": "primary",
                          hover: "",
                          selectable: "",
                          "select-mode": "single",
                          responsive: "sm",
                          id: "preferencesTable",
                          "sticky-header": "500px",
                          items: _vm.mapList,
                          fields: _vm.mapFields,
                          bordered: "",
                          striped: "",
                          small: "",
                          "head-variant": "dark",
                          "empty-text": _vm.FormMSG(250, "No data found"),
                          "show-empty": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(action)",
                              fn: function ({ item, index }) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c("b-form-select", {
                                        attrs: { options: _vm.rolesOptions },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleChangeAction(
                                              $event,
                                              item,
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.role,
                                          callback: function ($$v) {
                                            _vm.$set(item, "role", $$v)
                                          },
                                          expression: "item.role",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          65438583
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
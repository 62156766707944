var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [{ name: "cell-resize", rawName: "v-cell-resize" }],
      class: {
        "d-flex flex-column content-item-cell-fixed position-relative": true,
        "content-item-cell-is-prod":
          _vm.isProduction && _vm.isCheckCell && _vm.isShowBarStatus,
        [`${_vm.setClassIsAskToDelete()}`]: true,
        "dashed-border": _vm.isHasAskToDeleteInDetails,
      },
      style: { width: `${_vm.mapResizeCell}px` },
      attrs: { id: _vm.isRefCellInfo },
    },
    [
      _vm.isCheckCell
        ? _c(
            "div",
            {
              class: {
                "d-flex flex-column justify-content-center cell-info": true,
                "w-default": _vm.cellLoading,
              },
            },
            [
              _vm.cellLoading
                ? _c("section", { staticClass: "loader-content" }, [
                    _c("div", { staticClass: "loader" }),
                  ])
                : _c("section", [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-start ml-1 mr-1 flex-grow-1",
                        },
                        [
                          _vm.isRest() !== "+0"
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.left.html",
                                      value: _vm.tooltipContentOvertime,
                                      expression: "tooltipContentOvertime",
                                      modifiers: {
                                        hover: true,
                                        left: true,
                                        html: true,
                                      },
                                    },
                                  ],
                                  staticClass: "mr-1",
                                  staticStyle: { "font-size": "12px" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "badge badge-info" },
                                    [_vm._v(_vm._s(_vm.isRest()))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-row justify-content-end flex-grow-1",
                        },
                        [
                          _vm.getStatusItemSendToPaie().status
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.left.html",
                                      value: `${_vm.getLabelStatusSendToPaie()}`,
                                      expression:
                                        "`${getLabelStatusSendToPaie()}`",
                                      modifiers: {
                                        hover: true,
                                        left: true,
                                        html: true,
                                      },
                                    },
                                  ],
                                  staticClass:
                                    "btn-transparent text-color-rhapsody-in-blue pop-info",
                                  attrs: { id: "is-send-to-paie" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "refused-content" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getStatusItemSendToPaie().icon
                                          ),
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "refused-badge" },
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass:
                                                "badge-send-paie-item",
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.SEND.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#9404d1",
                                                    size: 8,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.left.html",
                                      value: `${_vm.getLabelStatus(
                                        _vm.labelStatus
                                      )}`,
                                      expression:
                                        "`${getLabelStatus(labelStatus)}`",
                                      modifiers: {
                                        hover: true,
                                        left: true,
                                        html: true,
                                      },
                                    },
                                  ],
                                  staticClass:
                                    "btn-transparent text-color-rhapsody-in-blue pop-info",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "refused-content" },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getStatusItem()
                                          ),
                                        },
                                      }),
                                      _vm.checkTypeRefused()
                                        ? _c(
                                            "div",
                                            { staticClass: "refused-badge" },
                                            [
                                              _c(
                                                "b-badge",
                                                { staticClass: "badge-item" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.typeRefused())
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                        ]
                      ),
                    ]),
                    _vm.isPerDiem
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-column mt-0 pr-1 pl-1",
                              staticStyle: { gap: "3px" },
                            },
                            _vm._l(
                              _vm.formatDefaultContentCells(
                                _vm.fimalacCellData()
                              ),
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "d-flex justify-content-center align-items-center ml-rem-1 mr-rem-1 text-black",
                                    style: `background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`,
                                    attrs: {
                                      id: `popover-${item.id}-${index}`,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "flex-grow-1",
                                        style: `text-align: center; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width:${
                                          _vm.widthCell - 10
                                        }px; color: ${
                                          _vm.isColorLight(item.color)
                                            ? "black"
                                            : "white"
                                        }; font-weight: bold`,
                                        attrs: {
                                          id: `item-cell-info-${_vm.indexField}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.labelTextPerDiem(item))
                                        ),
                                      ]
                                    ),
                                    _vm.actionTypeRowRemove(item).isShowIcon
                                      ? _c(
                                          "span",
                                          { staticClass: "flex-grow-0" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-custom-ask-detail",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.handleDeleteItemRowDetail(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.actionTypeRowRemove(item)
                                                  .isRemove
                                                  ? _c(
                                                      _vm.getLucideIcon(
                                                        _vm.isGestionnaryOfPaie
                                                          ? _vm.ICONS.TRASH.name
                                                          : _vm.ICONS.X_CIRCLE
                                                              .name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.setColorType(
                                                              _vm.typeAskLevel(
                                                                item
                                                              ).type,
                                                              item.color
                                                            ),
                                                          size: 25,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm.actionTypeRowRemove(item)
                                                  .isUndo
                                                  ? _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.UNDO_2.name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.setColorType(
                                                              _vm.typeAskLevel(
                                                                item
                                                              ).type,
                                                              item.color
                                                            ),
                                                          size: 18,
                                                          "stroke-width": 3.8,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          "custom-class":
                                            "v-bootstrap-popover-custom",
                                          target: `popover-${item.id}-${index}`,
                                          triggers: "hover focus",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.tooltipPerDiemLabel(item)
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm.isAnalyTicalCode
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-column mt-0 pr-1 pl-1",
                              staticStyle: { gap: "3px" },
                            },
                            _vm._l(
                              _vm.formatDefaultContentCells(
                                _vm.fimalacCellData()
                              ),
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "d-flex justify-content-center align-items-center ml-rem-1 mr-rem-1 text-black content-row-detail",
                                    style: `background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`,
                                    attrs: {
                                      id: `popover-${item.id}-${index}`,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "row-info-content flex-grow-1",
                                        style: `text-align: center; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width:${
                                          _vm.widthCell - 10
                                        }px; color: ${
                                          _vm.isColorLight(item.color)
                                            ? "black"
                                            : "white"
                                        }; font-weight: bold`,
                                        attrs: {
                                          id: `item-cell-info-${_vm.indexField}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.labelTextAnalitical(item))
                                        ),
                                      ]
                                    ),
                                    _vm.actionTypeRowRemove(item).isShowIcon
                                      ? _c(
                                          "span",
                                          { staticClass: "flex-grow-0" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-custom-ask-detail",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.handleDeleteItemRowDetail(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.actionTypeRowRemove(item)
                                                  .isRemove
                                                  ? _c(
                                                      _vm.getLucideIcon(
                                                        _vm.isGestionnaryOfPaie
                                                          ? _vm.ICONS.TRASH.name
                                                          : _vm.ICONS.X_CIRCLE
                                                              .name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.setColorType(
                                                              _vm.typeAskLevel(
                                                                item
                                                              ).type,
                                                              item.color
                                                            ),
                                                          size: 25,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm.actionTypeRowRemove(item)
                                                  .isUndo
                                                  ? _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.UNDO_2.name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.setColorType(
                                                              _vm.typeAskLevel(
                                                                item
                                                              ).type,
                                                              item.color
                                                            ),
                                                          size: 18,
                                                          "stroke-width": 3.8,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-popover",
                                      {
                                        ref: "popover-info-cell-row",
                                        refInFor: true,
                                        attrs: {
                                          "custom-class":
                                            "v-bootstrap-popover-custom",
                                          target: `popover-${item.id}-${index}`,
                                          triggers: "hover focus",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.tooltipAnaliticalLabel(item)
                                            ),
                                          },
                                          on: {
                                            mouseenter: (e) =>
                                              _vm.genereateEvent(e, item),
                                            mouseleave: _vm.removeGenerateEvent,
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm.isPrestations
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-column mt-0 pr-1 pl-1",
                              staticStyle: { gap: "3px" },
                            },
                            _vm._l(
                              _vm.formatDefaultContentCells(
                                _vm.fimalacCellData()
                              ),
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "d-flex justify-content-center align-items-center ml-rem-1 mr-rem-1 text-black",
                                    style: `background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`,
                                    attrs: {
                                      id: `popover-${item.id}-${index}`,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "flex-grow-1",
                                        style: `text-align: center; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width:${
                                          _vm.widthCell - 10
                                        }px; color: ${
                                          _vm.isColorLight(item.color)
                                            ? "black"
                                            : "white"
                                        }; font-weight: bold;`,
                                        attrs: {
                                          id: `item-cell-info-${_vm.indexField}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.labelTextPrestation(item))
                                        ),
                                      ]
                                    ),
                                    _vm.actionTypeRowRemove(item).isShowIcon
                                      ? _c(
                                          "span",
                                          { staticClass: "flex-grow-0" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-custom-ask-detail",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.handleDeleteItemRowDetail(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.actionTypeRowRemove(item)
                                                  .isRemove
                                                  ? _c(
                                                      _vm.getLucideIcon(
                                                        _vm.isGestionnaryOfPaie
                                                          ? _vm.ICONS.TRASH.name
                                                          : _vm.ICONS.X_CIRCLE
                                                              .name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.setColorType(
                                                              _vm.typeAskLevel(
                                                                item
                                                              ).type,
                                                              item.color
                                                            ),
                                                          size: 25,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _vm.actionTypeRowRemove(item)
                                                  .isUndo
                                                  ? _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.UNDO_2.name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.setColorType(
                                                              _vm.typeAskLevel(
                                                                item
                                                              ).type,
                                                              item.color
                                                            ),
                                                          size: 18,
                                                          "stroke-width": 3.8,
                                                        },
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-popover",
                                      {
                                        attrs: {
                                          "custom-class":
                                            "v-bootstrap-popover-custom",
                                          target: `popover-${item.id}-${index}`,
                                          triggers: "hover focus",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.tooltipPrestationsLabel(item)
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
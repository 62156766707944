<template>
	<b-modal
		v-model="isOpen"
		ref="quick-modal-addition"
		:title="FormMSG(59, 'Quick addition')"
		header-class="header-class-modal-doc-package"
		size="xl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@ok.prevent="handleSubmit"
		dialog-class="dialog-custom"
	>
		<b-row class="d-flex align-items-center mb-3" v-if="isAuth">
			<b-col cols="4">
				<div class="d-flex text-color-rhapsody-in-blue">
					<div class="fs-16 fw-700">{{ FormMSG(541, 'Category : ') }}</div>
					<div class="fs-16 ml-3">
						{{ getCategory(categorySelected) }}
					</div>
				</div>
			</b-col>
			<b-col cols="4">
				<div class="d-flex text-color-rhapsody-in-blue">
					<div class="fs-16 fw-700">{{ FormMSG(581, 'Activity : ') }}</div>
					<div class="fs-16 ml-3">{{ getActivity(activitySelected) }}</div>
				</div>
			</b-col>
			<b-col cols="4" v-if="isShowDecors">
				<b-collapse :visible="addDecor === false">
					<b-form-group :label="FormMSG(215, 'Decors')">
						<div class="d-flex">
							<v-select
								id="supplier-selector"
								v-model="locationIdSelected"
								:options="locationList"
								close-on-select
								label="setName"
								class="grow-flex-auto"
								:reduce="(option) => option.id"
								:placeholder="FormMSG(452, 'Select a decor')"
								:clearable="false"
								@option:selecting="handleSetLocation"
							>
							</v-select>
							<div class="d-flex flex-row icon-group-border-append">
								<div
									:style="`width: 34px`"
									class="cursor-pointer d-inline-flex justify-content-center align-items-center"
									v-b-tooltip.hover.top
									:title="FormMSG(157, 'Add new decor')"
									@click="handleClickAddNewDecor"
								>
									<component :is="getLucideIcon('PlusCircle')" color="#00A09C" :size="18" :stroke-width="2.25" />
								</div>
							</div>
						</div>
					</b-form-group>
				</b-collapse>
				<b-collapse :visible="addDecor === true">
					<b-form-group :label="FormMSG(198, 'Name of decor')">
						<div class="d-flex flex-row w-100">
							<div class="d-flex flex-grow-1 flex-column">
								<div class="d-flex flex-row">
									<b-form-input v-model="decorName" :placeholder="FormMSG(199, 'Enter name')" />
									<div class="d-flex flex-row flex-grow-0">
										<div
											:style="`width: 34px`"
											class="cursor-pointer d-inline-flex justify-content-center align-items-center"
											v-b-tooltip.hover.top
											:title="FormMSG(137, 'Add from list')"
											@click="handleClickAddNewDecor"
										>
											<component :is="getLucideIcon('Undo2')" color="#00A09C" :size="20" :stroke-width="2.25" />
										</div>
										<b-button
											variant="none"
											:style="`width: 34px`"
											class="cursor-pointer d-inline-flex justify-content-center align-items-center"
											v-b-tooltip.hover.top
											:title="FormMSG(138, 'Send to list of decors')"
											style="border: 1px solid #00a09c; border-radius: 4px"
											@click="handleClickSave"
											:disabled="sendAction"
										>
											<component
												:is="getLucideIcon('Send')"
												color="#00A09C"
												:size="20"
												:stroke-width="2.25"
												style="transform: rotate(40deg)"
											/>
										</b-button>
									</div>
								</div>
								<div v-if="decorName.length === 0" class="invalid-feedback">
									{{ FormMSG(487, 'Decor name is required') }}
								</div>
							</div>
						</div>
					</b-form-group>
				</b-collapse>
			</b-col>
		</b-row>
		<b-row class="d-flex align-items-center mb-3" v-if="isAuth === false">
			<b-col cols="4">
				<div class="d-flex text-color-rhapsody-in-blue">
					<div class="fs-16 fw-700">{{ FormMSG(541, 'Category : ') }}</div>
					<div class="fs-16 ml-3">
						{{ getCategory(categorySelected) }}
					</div>
				</div>
			</b-col>
			<b-col cols="4">
				<div class="d-flex text-color-rhapsody-in-blue">
					<div class="fs-16 fw-700">{{ FormMSG(581, 'Activity : ') }}</div>
					<div class="fs-16 ml-3">{{ getActivity(activitySelected) }}</div>
				</div>
			</b-col>
			<b-col cols="4" v-if="isShowDecors">
				<b-collapse :visible="addDecor === false">
					<b-form-group :label="FormMSG(215, 'Decors')">
						<div class="d-flex">
							<v-select
								id="supplier-selector"
								v-model="locationIdSelected"
								:options="locationList"
								close-on-select
								label="setName"
								class="grow-flex-auto"
								:reduce="(option) => option.id"
								:placeholder="FormMSG(452, 'Select a decor')"
								:clearable="false"
								@option:selecting="handleSetLocation"
							>
							</v-select>
							<div class="d-flex flex-row icon-group-border-append">
								<div
									:style="`width: 34px`"
									class="cursor-pointer d-inline-flex justify-content-center align-items-center"
									v-b-tooltip.hover.top
									:title="FormMSG(157, 'Add new decor')"
									@click="handleClickAddNewDecor"
								>
									<component :is="getLucideIcon('PlusCircle')" color="#00A09C" :size="18" :stroke-width="2.25" />
								</div>
							</div>
						</div>
					</b-form-group>
				</b-collapse>
				<b-collapse :visible="addDecor === true">
					<b-form-group :label="FormMSG(198, 'Name of decor')">
						<div class="d-flex flex-row w-100">
							<div class="d-flex flex-grow-1 flex-column">
								<div class="d-flex flex-row">
									<b-form-input v-model="decorName" :placeholder="FormMSG(199, 'Enter name')" />
									<div class="d-flex flex-row flex-grow-0">
										<div
											:style="`width: 34px`"
											class="cursor-pointer d-inline-flex justify-content-center align-items-center"
											v-b-tooltip.hover.top
											:title="FormMSG(137, 'Add from list')"
											@click="handleClickAddNewDecor"
										>
											<component :is="getLucideIcon('Undo2')" color="#00A09C" :size="20" :stroke-width="2.25" />
										</div>
										<b-button
											variant="none"
											:style="`width: 34px`"
											class="cursor-pointer d-inline-flex justify-content-center align-items-center"
											v-b-tooltip.hover.top
											:title="FormMSG(138, 'Send to list of decors')"
											style="border: 1px solid #00a09c; border-radius: 4px"
											@click="handleClickSave"
											:disabled="sendAction"
										>
											<component
												:is="getLucideIcon('Send')"
												color="#00A09C"
												:size="20"
												:stroke-width="2.25"
												style="transform: rotate(40deg)"
											/>
										</b-button>
									</div>
								</div>
								<div v-if="decorName.length === 0" class="invalid-feedback">
									{{ FormMSG(487, 'Decor name is required') }}
								</div>
							</div>
						</div>
					</b-form-group>
				</b-collapse>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-table
					v-if="$screen.width >= 992"
					selected-variant="primary"
					hover
					selectable
					select-mode="single"
					responsive="sm"
					ref="documentPackageTable"
					sticky-header="500px"
					:items="dataList"
					style="text-align: left"
					:fields="tableFields"
					bordered
					striped
					small
					head-variant="dark"
					:empty-text="FormMSG(250, 'No data found')"
					tbody-tr-class="row-class"
					show-empty
				>
					<template #cell(amount)="{ item, index }">
						<div v-if="item.isAmountHidden" class="d-flex flex-row align-items-center justify-content-end gap-1">
							<div>{{ getCurrency(item) }}</div>
						</div>
						<div v-else>
							<b-form-group v-if="$v.dataList.$each.$iter[index]" class="mb-0">
								<b-input-group>
									<b-form-input
										v-model="$v.dataList.$each.$iter[index].amount.$model"
										:state="!$v.dataList.$each.$iter[index].amount.$error && isAuth ? true : ''"
										@input="(value) => handleChangeAmount(value, index)"
									/>
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
								<div v-if="isAuth !== false && $v.dataList.$each.$iter[index].amount.$invalid" class="invalid-feedback">
									{{ FormMSG(181, 'Amount is required or must greater 0') }}
								</div>
							</b-form-group>
						</div>
					</template>
					<template #cell(quantity)="{ item, index }">
						<div class="d-flex flex-row align-items-center justify-content-center">
							<div v-if="item.isAmountHidden">
								{{ getQuantity(item) }}
							</div>
							<div v-else>
								<b-form-group class="mb-0">
									<b-input-group>
										<b-form-input type="number" :min="1" :step="1" v-model="$v.dataList.$each.$iter[index].$model.quantity" />
									</b-input-group>
								</b-form-group>
							</div>
						</div>
						<!-- <b-form-group class="mb-0">
							<b-input-group>
								<b-form-input type="number" :min="1" :step="1" v-model="$v.dataList.$each.$iter[index].$model.quantity" />
							</b-input-group>
						</b-form-group> -->
					</template>
				</b-table>
			</b-col>
		</b-row>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(585, 'Information')"
			class="modal-danger"
			v-model="openModalItemRegissorExist"
			no-close-on-backdrop
			ok-variant="danger"
			ok-only
		>
			<div v-html="titleInfoActionRegissor"></div>
			<template #modal-footer>
				<div class="d-flex flex-row justify-content-end w-100 gap-1 w-1">
					<div>
						<b-button variant="custom-outline-gray" size="sm" class="border-1" @click="handleAddActionRegissor(cancel)">
							{{ FormMSG(589, 'Cancel') }}
						</b-button>
					</div>
					<div>
						<b-button variant="outline-primary" size="sm" class="border-1" @click="handleAddActionRegissor(replace)">
							{{ FormMSG(588, 'Replace') }}
						</b-button>
					</div>
					<div>
						<b-button variant="primary" size="sm" class="border-1" @click="handleAddActionRegissor(add)">
							{{ FormMSG(587, 'Add') }}
						</b-button>
					</div>
				</div>
			</template>
		</b-modal>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minValue, decimal } from 'vuelidate/lib/validators';
import CurrencySvg from '@/components/Svg/Currency';
import _ from 'lodash';
import { addTsDaysUsersForPlanning } from '@/cruds/timesheet.crud';
import { newLocation, updateLocation } from '@/cruds/locations.crud';
import { formatDatesInString } from '@/shared/constants';
import { rendCurrency } from '~helpers';

export default {
	name: 'QuickAddition',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		open: { type: Boolean, default: false, required: false },
		externalData: { type: Array, default: () => [], required: true },
		isAuth: {
			type: Boolean,
			required: false,
			default: false
		},
		locationList: {
			type: Array,
			required: false,
			default: () => []
		},
		activeLocation: {
			type: Object,
			required: false,
			default: () => ({})
		},
		headerTabPlanning: {
			type: Array,
			required: false,
			default: () => []
		},
		dataPlanningSelect: {
			type: Array,
			required: false,
			default: () => []
		}
	},

	components: {
		CurrencySvg
	},

	data() {
		return {
			loadingSubmit: false,
			dataList: [],

			resultPrepareDataToSend: [],
			categorySelected: {
				label: ''
			},
			activitySelected: '',
			payloadLocation: null,
			locationIdSelected: '0',
			addDecor: false,
			decorName: '',
			dataListRegissor: [],
			openModalItemRegissorExist: false,
			add: 'add',
			cancel: 'cancel',
			replace: 'replace',
			functionNameOfRegissor: '',
			titleInfoActionRegissor: '',
			fullNameOfResgissor: ''
		};
	},

	watch: {
		open: {
			handler(newVal) {
				if (newVal) {
					if (this.dataListRegissor.length !== 0) {
						this.openModalItemRegissorExist = true;
					} else {
						this.openModalItemRegissorExist = false;
					}
				} else {
					this.openModalItemRegissorExist = false;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		dataPlanningSelect: {
			handler(newVal) {
				if (this.isAuth && newVal.length !== 0) {
					let item = newVal[0].tsDay ? newVal[0].tsDay : null;
					let departmentName = newVal[0].dataAdd ? newVal[0].dataAdd.departmentName : null;
					let functionName = newVal[0].dataAdd ? newVal[0].dataAdd.functionName : null;
					if (!_.isNil(item) && item.validated === 0) {
						let fullName = item.user.name + ' ' + item.user.firstName;
						let response = item.tsDayDetails.map((_item) => ({
							activity: _item.activity || null,
							amount: _item.amount || 0,
							category: _item.category || null,
							departmentName,
							functionName,
							fullName,
							dates: [formatDatesInString(item.date, 'DD/MM/YYYY')],
							flagItem: _item.projectFlagItem,
							userId: +item.user.id,
							quantity: _item.quantity || 1
						}));
						this.dataListRegissor = response;
						if (this.dataListRegissor.length !== 0) {
							this.functionNameOfRegissor = newVal[0].dataAdd.functionName;
							this.fullNameOfResgissor = newVal[0].dataAdd.fullName;
							this.titleInfoActionRegissor = `<div>${this.FormMSG(
								592,
								'The manager has already added data for this day which has not yet been transmitted on this date:'
							)}</div><div>${this.functionNameOfRegissor} : ${this.fullNameOfResgissor}.</div><div>${this.FormMSG(
								593,
								'Do you want to replace items or add them to this day'
							)}`;
							this.openModalItemRegissorExist = true;
						} else {
							this.openModalItemRegissorExist = false;
							this.functionNameOfRegissor = '';
							this.fullNameOfResgissor = '';
							this.titleInfoActionRegissor = '';
						}
					} else {
						this.dataListRegissor = [];
						this.functionNameOfRegissor = '';
						this.fullNameOfResgissor = '';
						this.titleInfoActionRegissor = '';
					}
				} else {
					this.dataListRegissor = [];
					this.functionNameOfRegissor = '';
					this.fullNameOfResgissor = '';
					this.titleInfoActionRegissor = '';
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		externalData: {
			handler(newVal) {
				this.dataList = [];
				if (newVal.length > 0) {
					let data = newVal.map((item) => ({ ...item, quantity: 1 }));
					this.categorySelected = newVal[0].category;
					this.activitySelected = newVal[0].activity;
					const fromatData = data.map((item) => {
						let isAmountHidden = false;
						const { userId, managerSalaryAcess, flagItem } = item;
						const { groupingCategory: type } = flagItem;
						const isItem = managerSalaryAcess.find(({ managerID, salaryType }) => +managerID === +userId && +salaryType === +type);
						if (!this.isAuth && isItem) {
							isAmountHidden = true;
						}
						return { ...item, isAmountHidden };
					});
					this.dataList = fromatData;
				}
			},
			immediate: true,
			deep: true
		},
		showPreferencesModal: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		activeLocation: {
			handler(newVal) {
				if (newVal !== null) {
					this.locationIdSelected = newVal.id;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		locationIdSelected: {
			handler(newVal) {
				let _data = this.locationList.find((item) => +item.id === +newVal);
				if (_data !== undefined) {
					this.payloadLocation = { ..._data };
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isShowDecors() {
			let data = this.externalData;
			if (data.length === 1 && data[0].dates.length === 1) {
				return true;
			} else {
				return false;
			}
		},
		sendAction() {
			return this.decorName.length !== 0 ? false : true;
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		tableFields() {
			return [
				{
					key: 'fullName',
					label: this.FormMSG(48, 'Name'),
					class: 'text-left align-middle w-25',
					sortable: true
				},
				{
					key: 'functionName',
					label: this.FormMSG(168, 'Function'),
					class: 'text-left align-middle w-25',
					sortable: true
				},
				{
					key: 'flagItemInfo',
					label: this.FormMSG(53, 'Section'),
					class: 'text-left align-middle w-20',
					formatter: (value, key, item) => {
						return `${item.flagItem.short} - ${item.flagItem.name}`;
					},
					sortable: false
				},
				{
					key: 'amount',
					label: this.FormMSG(51, 'Amount'),
					class: 'text-left align-middle w-25',
					formatter: (value, key, item) => {
						return 0;
					},
					sortable: false
				},
				{
					key: 'quantity',
					label: this.FormMSG(54, 'Quantity'),
					class: 'text-left align-middle w-10',
					formatter: (value, key, item) => {
						return 0;
					}
				}
			];
		}
	},

	methods: {
		async handleAddActionRegissor(type) {
			const data = this.dataList;
			const { category: new_category, activity: new_activity, dates: new_dates } = data[0];
			if (type === this.add) {
				const dataListRegissor = this.dataListRegissor.map((item) => ({ ...item, dates: new_dates, activity: new_activity, category: new_category }));
				for (let i = 0; i < dataListRegissor.length; i++) {
					const new_item = dataListRegissor[i];
					const { fullName: defaultName, functionName: defaultFunctionName, flagItem: defaultFlagItem } = new_item;
					const defaultRubric = `${defaultFlagItem.short} - ${defaultFlagItem.name}`;
					const isChecked = (data, field) => {
						return data[field](({ fullName, functionName, flagItem }) => {
							const rubric = `${flagItem.short} - ${flagItem.name}`;
							const isValid = fullName === defaultName && functionName === defaultFunctionName && rubric === defaultRubric;
							if (isValid) return true;
							return false;
						});
					};
					const isExist = isChecked(this.dataList, 'find');
					if (isExist) {
						const indexOfItem = isChecked(this.dataList, 'findIndex');
						const { amount, dates, quantity } = isExist;
						this.dataList[indexOfItem] = { ...this.dataList[indexOfItem], amount, dates, quantity };
					} else {
						this.dataList = [...this.dataList, new_item];
					}
				}
				this.openModalItemRegissorExist = false;
			}
			if (type === this.replace) {
				this.dataList = data;
				this.openModalItemRegissorExist = false;
			}
			if (type === this.cancel) {
				this.openModalItemRegissorExist = false;
				await this.emitEventClose();
			}
		},
		getCurrency(item) {
			if (item) {
				const { isAmountHidden, amount } = item;
				if (isAmountHidden) {
					const convertAmount = rendCurrency(parseFloat(amount), 3);
					const unite = `${convertAmount}`.replaceAll('K', '').split(' ')[1];
					return `Xx.xx ${unite}`;
				}
				return amount;
			} else {
				const convertAmount = rendCurrency(0, 3);
				const unite = `${convertAmount}`.replaceAll('K', '').split(' ')[1];
				return `Xx.xx ${unite}`;
			}
		},
		getQuantity(item) {
			if (item) {
				const { isAmountHidden, quantity } = item;
				if (isAmountHidden) {
					return 'Xx';
				}
				return quantity;
			}
			return 1;
		},
		handleChangeAmount(value, index) {
			if (isNaN(value)) {
				this.$v.dataList.$each.$iter[index].amount.$model = 0;
			} else {
				this.$v.dataList.$each.$iter[index].amount.$model = value;
			}
		},
		getCategory(categorySelected) {
			return categorySelected && categorySelected.label ? this.setTextCategoriesWithActivities(categorySelected.label) : 'None';
		},
		getActivity(activitySelected) {
			return activitySelected ? activitySelected.short + ' - ' + activitySelected.name : 'None';
		},
		handleClickAddNewDecor() {
			this.addDecor = !this.addDecor;
		},
		async handleClickSave() {
			let display = false;
			this.$v.$touch();
			if (this.$v.$invalid) return;
			if (this.addDecor && this.decorName.length !== 0) {
				display = true;
			} else {
				display = false;
			}
			const resultNewLocation = await newLocation();
			let _locationDates = [...this.externalData]
				.map((item) => item.dates)
				.flat(Infinity)
				.map((_date) => ({ date: formatDatesInString(new Date(_date), 'YYYY-MM-DD') + 'T00:00:00Z', display }));
			let date_start = _locationDates[0].date;
			let date_end = _locationDates[_locationDates.length - 1].date;
			let resultUpdLocation = await updateLocation(resultNewLocation.id, {
				setName: this.decorName,
				getInDate: date_start,
				getOutDate: date_end,
				locationDates: _locationDates
			});
			if (resultUpdLocation) {
				let strTitle = this.FormMSG(451, 'Success');
				this.$bvToast.toast(this.FormMSG(139, 'Decor added successfully!'), {
					title: strTitle,
					variant: 'success',
					toaster: 'b-toaster-top-center',
					solid: true
				});
				this.locationList.push(resultUpdLocation);
				this.$nextTick(() => {
					this.locationIdSelected = resultUpdLocation.id;
				});

				this.addDecor = false;
			} else {
				let strTitle = this.FormMSG(140, 'Error');
				this.$bvToast.toast(this.FormMSG(141, 'Error to added decors'), {
					title: strTitle,
					variant: 'error',
					toaster: 'b-toaster-top-center',
					solid: true
				});
			}
		},
		handleSetLocation(data) {
			this.payloadLocation = { ...data };
		},
		setTextCategoriesWithActivities(text) {
			if (this.activitySelected !== null) {
				let short = this.activitySelected.short;
				let textExact = text.toString().replace('$', short);
				return textExact;
			}
			return text;
		},
		async handleSubmit() {
			this.$emit('on-active-is-loading', true);
			if (this.isAuth) {
				this.$v.dataList.$touch();
				this.loadingSubmit = true;
				if (this.$v.$invalid) {
					this.loadingSubmit = false;
					this.$emit('on-active-is-loading', false);
					return false;
				}
			}
			const { data } = this.generateItems();
			const overWrite = this.isAuth && this.dataListRegissor.length !== 0;
			try {
				const res = await addTsDaysUsersForPlanning(data, null, overWrite);
				if (res) {
					this.createToastForMobile(this.FormMSG(81, 'Success'), this.FormMSG(82, 'Data added successfully!'));
				}
			} catch (error) {
				throw new Error(error.message);
			} finally {
				this.loadingSubmit = false;
				this.decorName = '';
				this.emitEventSubmit();
				await this.emitEventClose();
				this.$emit('on-active-is-loading', false);
			}
			return true;
		},
		getLocationByDate(element) {
			let result = [...this.headerTabPlanning.slice(1)].find((item) => item.key === element);
			if (result !== undefined) {
				return +result.id;
			}
			return 0;
		},
		generateItems() {
			const newData = this.dataList.flatMap((option) =>
				option.dates.map((date) => {
					const { flagItem } = option;
					const formattedDate = formatDatesInString(date, 'YYYY-MM-DD') + 'T00:00:00Z';
					return {
						userId: +option.userId,
						date: formattedDate,
						category: option.category ? +option.category.data.category : null,
						projectFlagItem: option.activity || null,
						quantity: +option.quantity,
						allowances: [
							{
								...flagItem,
								amount: option.amount ? +option.amount : 0,
								quantity: +option.quantity
							}
						],
						locationId: this.isShowDecors ? +this.locationIdSelected : this.getLocationByDate(date)
					};
				})
			);
			const data = newData.reduce((acc, current) => {
				const { userId: currentUserID, date: currentDate, allowances: currentAllowances } = current;
				const inAcc = acc.find(({ userId, date }) => userId === currentUserID && date === currentDate);
				if (inAcc) {
					inAcc.allowances.push(...currentAllowances);
				} else {
					acc.push(current);
				}
				return acc;
			}, []);
			return { data };
		},
		emitEventSubmit() {
			this.$emit('quick-addition:submitted');
		},
		async emitEventClose() {
			this.$emit('quick-addition:close');
			this.openModalItemRegissorExist = false;
			const showQuickAdditionModal = this.$refs['quick-modal-addition'].$parent.$parent.$parent.showQuickAdditionModal;
			const result = await new Promise((resolve, _) => resolve(!showQuickAdditionModal)).then((res) => {
				if (res) {
					this.resultPrepareDataToSend = [];
					this.categorySelected = {
						label: ''
					};
					return true;
				}
				return false;
			});
			return result;
		}
	},

	validations: {
		dataList: {
			$each: {
				amount: { required, minValue: minValue(1), decimal }
			}
		}
	}
};
</script>

<style scoped>
.bd-right {
	border-right: 2px solid #fff !important;
}
.btn-custom {
	display: flex;
	width: 28px;
	align-items: center;
	justify-content: center;
	height: 22px;
	border-radius: 0%;
	background-color: #cad0d7;
	border: none;
	outline: none;
}
.btn-custom:active,
.btn-custom:focus {
	outline: none !important;
	background: none !important;
}
.dialog-custom {
	max-width: 90% !important;
}
.gap-1 {
	gap: 10px;
}
</style>
<style>
.row-class {
	height: 40px !important;
}
</style>

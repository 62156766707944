var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextMenuPlanning", {
        ref: "menu",
        attrs: { model: _vm.mapItems, baseZIndex: 999 },
      }),
      _c(
        "div",
        [
          _c(
            "b-tooltip",
            {
              attrs: {
                "custom-class": "tooltip-custom-context-menu",
                show: _vm.showTooltip,
                target: `info-delete`,
                placement: "right",
              },
              on: {
                "update:show": function ($event) {
                  _vm.showTooltip = $event
                },
              },
            },
            [_vm._v(_vm._s(_vm.message))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
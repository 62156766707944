var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-row",
        { staticClass: "d-flex align-items-center" },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("h1", { staticClass: "h-text-color" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.convertTextDate(_vm.showDetailData.date)) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-start align-items-center",
              attrs: { cols: "6" },
            },
            [
              !_vm.isFilmSingle
                ? _c("h6", { staticClass: "h-text-color p-1" }, [
                    _c("span", [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(257, "Encoded by:")) +
                          "\n\t\t\t\t\t"
                      ),
                      _c("b", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.encoder(_vm.showDetailData)) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-end align-items-center",
              attrs: { cols: "6" },
            },
            [
              !_vm.isFilmSingle
                ? _c("h6", { staticClass: "p-1" }, [
                    _c("div", { staticClass: "name-title" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.getUserFullName(_vm.showDetailData)) +
                          "\n\t\t\t\t\t"
                      ),
                      _c("span", { staticClass: "divider-dot" }),
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.getUserFunctionName(_vm.showDetailData)) +
                          "\n\t\t\t\t"
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "4" } }, [
            _c("h6", { staticClass: "p-1" }, [
              _c("div", { staticClass: "name-title" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.FormMSG(13, "Status")) +
                    "\n\t\t\t\t"
                ),
              ]),
            ]),
          ]),
          _c("b-col", { attrs: { cols: "8" } }, [
            _c(
              "div",
              {
                staticClass:
                  "wrap-status d-flex justify-content-end align-items-end p-1",
              },
              [
                _c(
                  "div",
                  {
                    class: `status ${_vm.classStatus(
                      _vm.showDetailData.validated
                    )}`,
                    staticStyle: { "font-size": "0.675rem" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.getLabelStatus(_vm.showDetailData)) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c("b-col", [
            _c("div", { staticClass: "details-timesheet" }, [
              _c(
                "ul",
                [
                  _c("li", [
                    _c("div", { staticClass: "column-flag-1" }, [
                      _vm._v(_vm._s(_vm.FormMSG(14, "Rubric"))),
                    ]),
                    _c("div", { staticClass: "column-flag-2" }, [
                      _vm._v(_vm._s(_vm.FormMSG(8, "Category"))),
                    ]),
                    _c("div", { staticClass: "column-flag-3" }, [
                      _vm._v(_vm._s(_vm.FormMSG(9, "Activity"))),
                    ]),
                    _c("div", { staticClass: "column-flag-4" }, [
                      _vm._v(_vm._s(_vm.FormMSG(10, "Decors"))),
                    ]),
                    _c("div", { staticClass: "column-flag-5" }, [
                      _vm._v(_vm._s(_vm.FormMSG(11, "Quantity"))),
                    ]),
                    _c("div", { staticClass: "column-flag-6" }, [
                      _vm._v(_vm._s(_vm.FormMSG(12, "Amount"))),
                    ]),
                    _c("div", { staticClass: "column-flag-7" }, [
                      _vm._v(_vm._s(_vm.FormMSG(15, "Total"))),
                    ]),
                  ]),
                  (_vm.showDetailData.validated === 4 ||
                    _vm.showDetailData.validated === 16) &&
                  _vm.showDetailData.comment &&
                  _vm.showDetailData.comment.length > 0
                    ? _c(
                        "li",
                        { staticClass: "timesheet-comment border-0 rounded-0" },
                        [_c("p", [_vm._v(_vm._s(_vm.showDetailData.comment))])]
                      )
                    : _vm._e(),
                  _vm._l(
                    _vm.showDetailData.tsDayDetails,
                    function (mapInfo, keyInfo) {
                      return _c("li", { key: keyInfo }, [
                        _c("div", { staticClass: "column-flag-1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center p-1 text-black px-3 h-auto",
                              style: `background-color: #${mapInfo.projectFlagItem.color}; width: auto; border-radius: 4px;height: 20px;margin-top: 2px;`,
                            },
                            [
                              _c(
                                "span",
                                {
                                  style: `color: ${
                                    _vm.isColorLight(
                                      `#${mapInfo.projectFlagItem.color}`
                                    )
                                      ? "black"
                                      : "white"
                                  }; font-weight: bold`,
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.getProjectFlagToText(mapInfo))
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "column-flag-2 mr-1 ml-2" }, [
                          _vm._v(_vm._s(_vm.setCategory(mapInfo))),
                        ]),
                        _c("div", { staticClass: "column-flag-3 mr-1 ml-2" }, [
                          _vm._v(_vm._s(_vm.setActivity(mapInfo))),
                        ]),
                        _c("div", { staticClass: "column-flag-4 mr-1 ml-2" }, [
                          _vm._v(_vm._s(_vm.setLocationName(mapInfo))),
                        ]),
                        _c("div", { staticClass: "column-flag-5 mr-1 ml-2" }, [
                          _vm._v(_vm._s(_vm.getQuantity(mapInfo))),
                        ]),
                        _c("div", { staticClass: "column-flag-6 mr-1 ml-2" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(mapInfo))),
                        ]),
                        _c("div", { staticClass: "column-flag-7 mr-1 ml-2" }, [
                          _vm._v(_vm._s(_vm.setTotal(mapInfo))),
                        ]),
                      ])
                    }
                  ),
                ],
                2
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
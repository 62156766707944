<style></style>
<template>
	<div class="clearfix mb-0">
		<div>
			<b-row align-h="between">
				<b-col class="pb-0 ml-1">
					<b-input-group>
						<b-form-input
							v-model.trim="filter"
							type="text"
							:placeholder="this.FormMSG(1, 'Type to search...')"
							id="filterInput"
							@keyup.enter="(e) => handleChangeKeyUp(e)"
							@input="(e) => handleChangeInput(e)"
							autocomplete="off"
							v-b-tooltip.focus
						/>
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<component
									:is="getLucideIcon(ICONS.SEARCH.name)"
									:color="ICONS.SEARCH.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									v-if="filter.length === 0"
								/>
								<component
									:is="getLucideIcon(ICONS.X.name)"
									:color="ICONS.X.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									@click="() => initReset()"
									v-else
								/>
							</b-input-group-text>
							<b-tooltip target="filterInput" placement="top" triggers="focus">
								{{ this.FormMSG(2, "Type 'Enter' to launch search") }}
							</b-tooltip>
						</b-input-group-append>
					</b-input-group>
				</b-col>
				<b-col class="pb-0 ml-1">
					<b-form-group>
						<v-select
							class="w-100"
							v-model="department"
							:placeholder="FormMSG(3, 'Select department')"
							:options="mapDepartments"
							label="message"
							:clearable="true"
							@option:selected="handleSelectDepartement"
							@input="handleSelectInputDepartement"
							:reduce="(option) => option.value"
						/>
					</b-form-group>
				</b-col>
				<b-col class="pb-0 ml-1">
					<b-form-group>
						<v-select
							class="w-100"
							:disabled="department === null || department.length === 0"
							v-model="functionFilter"
							:placeholder="FormMSG(4, 'Select function')"
							:options="mapFunctions"
							label="message"
							:clearable="true"
							@option:selected="handleSelectFunction"
							@input="handleSelectInputFunction"
							:reduce="(option) => option.value"
						/>
					</b-form-group>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { getDepartments } from '@/cruds/department.crud';
import { getFunctions } from '@/cruds/department.crud';
import moment from 'moment';
export default {
	name: 'HeaderPlanningFimalac',
	components: {},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		id: {
			type: String,
			required: false
		},
		onSearch: {
			type: Function,
			required: false
		}
	},
	data: () => {
		return {
			filter: '',
			allDay: {
				value: -1
			},
			currentDay: {},
			reset: true,
			notReset: false,
			department: '',
			functionFilter: '',
			mapDepartments: [],
			mapFunctions: [],
			Days: []
		};
	},
	created() {
		this.getDepartment();
		this.initDays();
	},
	computed: {},
	watch: {
		filter: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		department: {
			async handler(newVal) {
				if (newVal && (newVal !== null || newVal.length !== 0)) {
					let departmentId = +newVal;
					this.functionFilter = '';
					let functions = await getFunctions(departmentId);
					this.mapFunctions = functions;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		functionFilter: {
			async handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		handleChangeKeyUp(e) {
			e.preventDefault();
			return this.onSearch(this.filter, this.notReset, this.id);
		},
		handleChangeInput(text) {
			if (text.length === 0) {
				this.filter = text;
				return this.onSearch(this.filter, this.notReset, this.id);
			}
		},
		initDays(months) {
			let curMonthAndYear;
			this.Days = [];
			this.allDay.text = this.FormMSG(7, 'All day');
			this.Days.push(this.allDay);
			if (!months) {
				curMonthAndYear = moment(Date.now()).format('YYYY-MM');
			} else {
				curMonthAndYear = moment(months, 'MM/YYYY').format('YYYY-MM');
			}
			const monthStart = moment(`${curMonthAndYear}-01`);
			const numDays = monthStart.daysInMonth();
			const langCurrent = this.currentLang;

			const dates = [];
			for (let day = 1; day <= numDays; day++) {
				const date = moment(`${curMonthAndYear}-${day}`);
				const formattedDate = {
					text: date.locale(langCurrent).format('DD - dddd'),
					value: date.format('DD/MM/YYYY')
				};
				dates.push(formattedDate);
			}
			this.Days.push(dates);
			this.currentDay = this.allDay;
		},
		initReset() {
			this.filter = '';
			return this.onSearch(this.filter, this.reset, this.id);
		},
		async getDepartment() {
			let result = await getDepartments(false, false);
			this.mapDepartments = result.filter((dep) => +dep.value !== 0);
			return this.mapDepartments;
		},
		async handleSelectDepartement(item) {
			if (this.filter.length !== 0) {
				this.filter = '';
				setTimeout(() => {
					this.department = item.value;
					this.$emit('handleSelectDepartementChange', item);
				}, 1500);
			} else {
				this.$emit('handleSelectDepartementChange', item);
			}
		},
		handleSelectInputDepartement(item) {
			if (item === null) {
				this.filter = '';
				let data = {
					value: null,
					message: null,
					id: null
				};
				this.mapFunctions = [];
				this.functionFilter = '';
				this.$emit('handleSelectDepartementChange', data);
			}
		},
		handleSelectFunction(item) {
			if (this.filter.length !== 0) {
				this.filter = '';
				setTimeout(() => {
					this.functionFilter = item.value;
					this.$emit('handleSelectFunctionChange', item);
				}, 1500);
			} else {
				this.$emit('handleSelectFunctionChange', item);
			}
		},
		handleSelectInputFunction(item) {
			if (item === null) {
				this.filter = '';
				let data = {
					value: null,
					message: null,
					id: null
				};
				this.$emit('handleSelectFunctionChange', data);
			}
		}
	}
};
</script>
<style scoped></style>

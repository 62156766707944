var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-content-center flex-grow-1" },
    [
      _c(
        "b-form-checkbox",
        {
          key: _vm.option.id,
          attrs: {
            size: "lg",
            value: true,
            "aria-describedby": _vm.option.name,
          },
          on: { change: _vm.handleAddFlag },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.bottom.html",
                  value: _vm.showToolTipFlagItem(_vm.option),
                  expression: "showToolTipFlagItem(option)",
                  modifiers: { hover: true, bottom: true, html: true },
                },
              ],
              staticClass:
                "d-flex justify-content-center align-items-center p-1 text-black px-3",
              style: `background-color: ${_vm.setBackGround(_vm.option.code)}; 
				color:${_vm.isColorLight(_vm.option.code) ? "black" : "white"}; 
				border-radius: 4px;height: 20px;
    			margin-top: 2px; cursor: pointer`,
            },
            [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.setText(_vm.option.text)) + "\n\t\t\t"
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-bootstrap-tooltip-custom" }, [
    _vm.IsRow
      ? _c(
          "div",
          { staticClass: "d-flex flex-column align-items-center" },
          [
            [
              _vm._l(_vm.mapDetail, function (_item, index) {
                return _vm.mapDetail.length !== 0
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticStyle: { width: "200px !important" },
                      },
                      _vm._l(
                        _vm.mapTotalCostCenter(_item.totalByCostCenter),
                        function (row, rowIndex) {
                          return _c(
                            "div",
                            {
                              key: rowIndex,
                              style: {
                                borderBottom:
                                  index === _vm.mapDetail.length - 1
                                    ? ""
                                    : "1px solid #e4dfec",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row align-items-center p-1",
                                  style: {
                                    borderBottom:
                                      rowIndex ===
                                      _vm.mapTotalCostCenter(
                                        _item.totalByCostCenter
                                      ).length -
                                        1
                                        ? ""
                                        : "1px solid #e4dfec",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-transparent text-color-rhapsody-in-blue",
                                        attrs: {
                                          id: `tooltip-mini-${rowIndex}-${index}`,
                                        },
                                      },
                                      [
                                        _c(
                                          _vm.getLucideIcon(
                                            _vm.ICONS.INFO.name
                                          ),
                                          {
                                            tag: "component",
                                            attrs: {
                                              color: "#FFFFFF",
                                              size: 16,
                                              "stroke-width": 2.25,
                                            },
                                          }
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row align-items-center justify-content-start flex-grow-1",
                                      staticStyle: {
                                        gap: "10px",
                                        width: "60%",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        style: {
                                          width: "5px",
                                          height: "5px",
                                          backgroundColor: `#${row.color}`,
                                          borderRadius: "50%",
                                        },
                                      }),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getTextDefault(
                                              row.costCenterActivity
                                            ).text
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", {
                                    staticStyle: {
                                      "margin-left": "8px",
                                      height: "20px",
                                      "border-left": "1px solid #e4dfec",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row justify-content-end flex-grow-1 w-25",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.rendAmountCurrency(row, false)
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: `tooltip-mini-${rowIndex}-${index}`,
                                    placement: "left",
                                    "custom-class":
                                      "v-bootstrap-tooltip-custom-content",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "v-bootstrap-tooltip-custom-mini",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-content-start",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row align-items-center justify-content-start flex-grow-1",
                                              staticStyle: { gap: "10px" },
                                            },
                                            [
                                              _c("div", {
                                                style: {
                                                  width: "5px",
                                                  height: "5px",
                                                  marginLeft: "5px",
                                                  backgroundColor: `#${row.color}`,
                                                  borderRadius: "50%",
                                                },
                                              }),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getTextDefault(
                                                      _vm.getPhase(row)
                                                    ).text
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "mini-content" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getTextDefault(
                                                    _vm.getCostCenter(row)
                                                  ).text
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e()
              }),
              _vm.mapDetail.length === 0
                ? _c("div", [_vm._v(_vm._s(_vm.FormMSG(1, "None")))])
                : _vm._e(),
            ],
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "d-flex flex-column align-items-center" },
          [
            [
              _vm._l(_vm.mapRowItem, function (_item, index) {
                return _vm.mapRowItem.length !== 0
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticStyle: { width: "200px !important" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row align-items-center p-1",
                            style: {
                              borderBottom:
                                index === _vm.mapRowItem.length - 1
                                  ? ""
                                  : "1px solid #e4dfec",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn-transparent text-color-rhapsody-in-blue",
                                  attrs: { id: `tooltip-mini-${index}` },
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.INFO.name), {
                                    tag: "component",
                                    attrs: {
                                      color: "#FFFFFF",
                                      size: 16,
                                      "stroke-width": 2.25,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row align-items-center justify-content-start flex-grow-1",
                                staticStyle: { gap: "10px", width: "60%" },
                              },
                              [
                                _c("div", {
                                  style: {
                                    width: "5px",
                                    height: "5px",
                                    marginLeft: "5px",
                                    backgroundColor: `#${_item.color}`,
                                    borderRadius: "50%",
                                  },
                                }),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getTextDefault(
                                        _item.costCenterActivity
                                      ).text
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", {
                              staticStyle: {
                                "margin-left": "8px",
                                height: "20px",
                                "border-left": "1px solid #e4dfec",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row justify-content-end flex-grow-1 w-25",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.rendAmountCurrency(_item, true)
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: `tooltip-mini-${index}`,
                              placement: "left",
                              "custom-class":
                                "v-bootstrap-tooltip-custom-content",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "v-bootstrap-tooltip-custom-mini",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-content-start",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row align-items-center justify-content-start flex-grow-1",
                                        staticStyle: { gap: "10px" },
                                      },
                                      [
                                        _c("div", {
                                          style: {
                                            width: "5px",
                                            height: "5px",
                                            marginLeft: "5px",
                                            backgroundColor: `#${_item.color}`,
                                            borderRadius: "50%",
                                          },
                                        }),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getTextDefault(
                                                _item.projectPhase
                                              ).text
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("div", { staticClass: "mini-content" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getTextDefault(_item.costCenter)
                                            .text
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _vm.mapRowItem.length === 0
                ? _c("div", [_vm._v(_vm._s(_vm.FormMSG(1, "None")))])
                : _vm._e(),
            ],
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
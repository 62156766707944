<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(73, 'Add crew member')"
		header-class="header-class-modal-doc-package"
		size="xxl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		:ok-only="searchExisting === false"
		@ok.prevent="handleOk"
		@show="handleShow"
		@cancel="handleCancel"
		dialog-class="dialog-custom"
	>
		<b-row class="mb-4">
			<b-col class="text-center">
				<b-button-group>
					<b-button :variant="`${!searchExisting ? '' : 'outline-'}primary`" :disabled="loadingAddSelected" @click="toggleSearchExisting">
						{{ FormMSG(27, 'Search existing') }}
					</b-button>
					<b-button :variant="`${searchExisting ? '' : 'outline-'}primary`" :disabled="loadingAddSelected" @click="toggleSearchExisting">
						{{ FormMSG(28, 'Add no-existing') }}
					</b-button>
				</b-button-group>
			</b-col>
		</b-row>

		<div style="min-height: 150px">
			<b-collapse :visible="searchExisting === false">
				<div v-if="searchExisting === false">
					<b-row class="mb-2">
						<b-col cols="6">
							<b-input-group>
								<b-form-input
									type="text"
									id="filterSearch"
									v-model="filter"
									:placeholder="FormMSG(65, 'Type to search')"
									@keyup.enter="handleKeyUpEnter"
								/>
								<b-input-group-append class="cursor-pointer">
									<b-input-group-text class="btn-search">
										<component :is="getLucideIcon('Search')" color="#FFFFFF" :size="16" :stroke-width="2.5" v-if="filter.length === 0" />
										<component :is="getLucideIcon('X')" color="#FFFFFF" :size="16" :stroke-width="2.5" @click="resetFilter" v-else />
									</b-input-group-text>
								</b-input-group-append>
								<b-tooltip target="filterSearch" placement="top" triggers="focus">
									{{ FormMSG(816, 'You must type more than 3 characters followed by enter to activate the search') }}
								</b-tooltip>
							</b-input-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col id="containerInviteNewUser" ref="containerInviteNewUser">
							<b-table
								v-if="$screen.width >= 992"
								selected-variant="primary"
								hover
								selectable
								select-mode="single"
								responsive="sm"
								ref="preferencesTable"
								id="preferencesTable"
								sticky-header="500px"
								:items="dataList"
								style="text-align: left"
								:fields="tableFields"
								bordered
								striped
								small
								head-variant="dark"
								:empty-text="FormMSG(500, 'Run a search to show matching records in this list')"
								show-empty
							>
								<template #head(checked)="data">
									<b-form-checkbox
										v-if="dataList.length > 0"
										v-model="selectAll"
										size="lg"
										:value="true"
										:unchecked-value="false"
										:disabled="loadingAddSelected"
										@change="handleChangeSelectAll"
									/>
								</template>
								<template #cell(checked)="{ item, index }">
									<div>
										<b-form-checkbox
											v-model="item.checked"
											:value="true"
											:unchecked-value="false"
											size="lg"
											:disabled="loadingAddSelected"
											@change="handleChangeItemSelect($event, item, index)"
										/>
									</div>
								</template>
								<template #cell(departmentName)="{ item, index }">
									<div v-if="!item.checked && !item.isEdit">{{ dataList[index] && dataList[index].departmentName }}</div>
									<div v-else>
										<treeselect
											:options="mapDepartment"
											v-model="dataList[index].department"
											:disable-branch-nodes="true"
											:clearable="false"
											@select="handleOptionSelectDepartment($event, index)"
											@open="boundaryTreeSelect()"
										>
											<div slot="value-label" slot-scope="{ node }" :title="node.label">
												<div class="ml-2">{{ node.label }}</div>
											</div>
											<div slot="option-label" slot-scope="{ node }">
												<div class="treeselect-label ml-2" :title="node.label">{{ node.label }}</div>
											</div>
										</treeselect>
									</div>
								</template>
								<template #cell(functionName)="{ item, index }">
									<div v-if="!item.checked && !item.isEdit">{{ dataList[index] && dataList[index].functionName }}</div>
									<div v-else>
										<div class="d-flex felx-row gap-4 flex-grow-1">
											<treeselect
												:options="dataList[index].mapFunctions"
												v-model="dataList[index].function"
												:disable-branch-nodes="true"
												:clearable="false"
												@select="handleOptionSelectFunction($event, index)"
												@open="boundaryTreeSelect()"
											>
												<div slot="value-label" slot-scope="{ node }" :title="node.label">
													<div class="ml-2">{{ node.label }}</div>
												</div>
												<div slot="option-label" slot-scope="{ node }">
													<div class="treeselect-label ml-2" :title="node.label">{{ node.label }}</div>
												</div>
											</treeselect>
											<b-button class="btn-plus flex-grow-0" size="sm" @click="handleAddFunction(item, index)">
												<component :is="getLucideIcon('Plus')" color="#06263E" :size="16" :stroke-width="2" />
											</b-button>
										</div>
									</div>
								</template>
								<template #cell(phone)="{ item, index }">
									<div v-if="!item.isEdit">{{ dataList[index] && dataList[index].phone }}</div>
									<div v-else>
										<b-form-input v-model="dataList[index].phone" placeholder="Enter phone"></b-form-input>
									</div>
								</template>
								<template #cell(email)="{ item, index }">
									<div v-if="!item.isEdit">{{ dataList[index] && dataList[index].email }}</div>
									<div v-else>
										<b-form-input v-model="dataList[index].email" placeholder="Enter email"></b-form-input>
										<div v-if="dataList[index].email.length !== 0">
											<div v-if="!$v.dataList.$each.$iter[index].email.email" class="invalid-feedback">
												{{ FormMSG(305, 'Email is invalid') }}
											</div>
										</div>
										<div v-else>
											<div v-if="!$v.dataList.$each.$iter[index].email.required" class="invalid-feedback">
												{{ FormMSG(306, 'Email is required') }}
											</div>
										</div>
									</div>
								</template>
								<template #cell(edit)="{ item, index }">
									<div class="d-flex flex-row" style="gap: 4px"></div>
									<b-button
										v-if="item.isEdit"
										variant="primary"
										size="sm"
										@click="closeUserEdit(item, index)"
										class="btn bg-transparent border-0"
										v-b-tooltip.hover.bottom.html="`${FormMSG(76, 'Close to edit')}`"
									>
										<component :is="getLucideIcon('Undo')" color="#225CBD" :size="20" />
									</b-button>
									<b-button
										v-b-tooltip.hover.bottom.html="`${!item.isEdit ? FormMSG(74, 'Edit member') : FormMSG(75, 'Valid to edit')}`"
										variant="primary"
										size="sm"
										@click="editUser(item, index)"
										class="btn bg-transparent border-0"
									>
										<component v-if="!item.isEdit" :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
										<component v-if="item.isEdit" :is="getLucideIcon('Save')" color="#47C7BF" :size="20" />
									</b-button>
								</template>
							</b-table>
							<div v-if="!isActiveSearch" class="end-of-list">{{ sizeOfUsers() }}</div>
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col cols="3" offset="9">
							<b-button variant="primary" :disabled="dataListChecked.length === 0 || loadingAddSelected" block @click="handleClickAddSelected">
								<b-spinner v-if="loadingAddSelected" small />
								{{ FormMSG(589, 'Add selected to project') }}
							</b-button>
						</b-col>
					</b-row>
				</div>
			</b-collapse>

			<b-collapse :visible="searchExisting === true">
				<div v-if="searchExisting === true">
					<b-row>
						<b-col cols="6">
							<b-form-group :label="FormMSG(251, 'First name')">
								<b-form-input
									v-model="$v.dataForAddNoExisting.firstName.$model"
									:placeholder="FormMSG(252, 'Enter first name')"
									:state="!$v.dataForAddNoExisting.firstName.$error"
								/>
								<div v-if="!$v.dataForAddNoExisting.firstName.minLength" class="invalid-feedback">
									{{ FormMSG(1540, 'First name must be greater 1 word') }}
								</div>
								<div v-if="!$v.dataForAddNoExisting.firstName.required" class="invalid-feedback">
									{{ FormMSG(155, 'First name is required') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group :label="FormMSG(253, 'Last name')">
								<b-form-input
									v-model="$v.dataForAddNoExisting.name.$model"
									:placeholder="FormMSG(254, 'Enter last name')"
									:state="!$v.dataForAddNoExisting.name.$error"
								/>
								<div v-if="!$v.dataForAddNoExisting.name.minLength" class="invalid-feedback">
									{{ FormMSG(1560, 'Name must be greater 1 word') }}
								</div>
								<div v-if="!$v.dataForAddNoExisting.name.required" class="invalid-feedback">
									{{ FormMSG(289, 'Name is required') }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="6">
							<b-form-group :label="FormMSG(303, 'Email')">
								<b-form-input
									v-model="$v.dataForAddNoExisting.email.$model"
									:placeholder="FormMSG(304, 'Enter email')"
									:state="!$v.dataForAddNoExisting.email.$error"
								/>
								<div v-if="!$v.dataForAddNoExisting.email.email" class="invalid-feedback">
									{{ FormMSG(305, 'Email is invalid') }}
								</div>
								<div v-if="!$v.dataForAddNoExisting.email.required" class="invalid-feedback">
									{{ FormMSG(306, 'Email is required') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group :label="FormMSG(307, 'Phone')">
								<b-form-input
									v-model="$v.dataForAddNoExisting.phone.$model"
									:placeholder="FormMSG(308, 'Enter phone')"
									:state="!$v.dataForAddNoExisting.phone.$error"
								/>
								<div v-if="!$v.dataForAddNoExisting.phone.phone" class="invalid-feedback">
									{{ FormMSG(309, 'Phone is invalid') }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="6">
							<b-form-group :label="FormMSG(257, 'Department')">
								<b-form-select
									v-model="$v.dataForAddNoExisting.department.$model"
									:options="mapAllDepartments"
									:placeholder="FormMSG(258, 'Please select')"
									:state="!$v.dataForAddNoExisting.department.$error"
									@change="handleChangeDepartment"
								/>
								<div v-if="$v.dataForAddNoExisting.department.$error" class="invalid-feedback">
									{{ FormMSG(296, 'Department is required') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group :label="FormMSG(259, 'Function')">
								<b-form-select
									v-model="$v.dataForAddNoExisting.function.$model"
									:options="allFunctions"
									:placeholder="FormMSG(258, 'Please select')"
									:state="!$v.dataForAddNoExisting.function.$error"
									:disabled="dataForAddNoExisting.department === null"
								/>
								<div v-if="$v.dataForAddNoExisting.function.$error" class="invalid-feedback">
									{{ FormMSG(301, 'Function is required') }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					v-if="searchExisting === true"
					size="md"
					variant="custom-outline-gray"
					style="margin-top: 5px"
					class="w-138-px mr-3"
					:disabled="loadingSubmit"
					@click="cancel"
					block
				>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button
					size="md"
					:variant="searchExisting === false ? 'custom-outline-gray' : 'primary'"
					class="w-138-px"
					:disabled="loadingSubmit"
					@click="ok"
					block
				>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">
							{{ searchExisting === false ? FormMSG(43, 'Cancel') : FormMSG(42, 'Save') }}
						</div>
					</div>
				</b-button>
			</div>
		</template>
		<new-function
			:open="openModalFunction"
			:title="FormMSG(300, 'Add new function')"
			@show-modal="handleShowModalFunction"
			@close-modal="handleCancelModalFunction"
			:row_data="row_data"
			@handle:submitted="handleSaveNewFunction"
		/>
	</b-modal>
</template>

<script>
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getUsersByLicence, addUsersToProject, updateUser } from '@/cruds/users.crud';
import { addFunction, deleteFunction, updateFunction } from '@/cruds/department.crud';
import { getTotalUsersCount } from '@/cruds/fimalac.crud';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required, email, minLength, minValue } from 'vuelidate/lib/validators';
import { getDepartments } from '@/cruds/department.crud';
import { getFunctions } from '@/cruds/department.crud';
import NewFunction from './NewFunction.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
	name: 'InviteNewUser',

	props: {
		open: { type: Boolean, default: false, required: false }
	},
	components: {
		NewFunction,
		Treeselect
	},
	mixins: [languageMessages, globalMixin, validationMixin],

	data() {
		return {
			searchExisting: false,
			filter: '',
			dataList: [],
			dataListChecked: [],
			selectAll: false,
			recordOffset: 0,
			recordLimit: 50,

			loadingSubmit: false,
			loadingAddSelected: false,

			dataForAddNoExisting: {
				name: '',
				firstName: '',
				email: '',
				phone: '',
				department: null,
				function: null
			},
			mapDepartment: [],
			mapFunction: [],
			allDepartments: [],
			allFunctions: [],
			alreadyLastData: false,
			openModalFunction: false,
			row_data: null,
			newFunctionName: '',
			globalUsers: [],
			totalOfUsers: 0,
			depAdministration: 400,
			isActiveSearch: false,
			langStore: 0
		};
	},
	watch: {
		allDepartments: {
			handler(newVal) {
				this.mapDepartment = [...newVal]
					.filter((dep) => dep.value !== 0)
					.map((item) => ({ id: item.value, label: item.text }))
					.slice(1);
				return newVal;
			},
			immediate: true,
			deep: true
		},
		isOpen: {
			handler(newVal) {
				if (newVal) {
					setTimeout(() => {
						this.getUsers();
						this.getTotalUsers();
					}, 50);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		filter: {
			handler(newVal) {
				let search = newVal.toString().trim();
				if (search.length <= 2) {
					setTimeout(() => {
						this.dataList = this.globalUsers;
						this.alreadyLastData = false;
						this.isActiveSearch = false;
					}, 150);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	validations: {
		dataForAddNoExisting: {
			name: { required, minLength: minLength(2) },
			firstName: { required, minLength: minLength(2) },
			email: { required, email },
			department: { required },
			function: { required },
			phone: { phone: (value) => (value.length !== 0 ? /^\+{0,1}[0-9]+$/.test(value) : true) }
		},
		dataList: {
			$each: {
				email: { required, email }
			}
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		mapAllDepartments() {
			return this.allDepartments.filter((dep) => dep.value !== 0);
		},
		tableFields() {
			return [
				{
					key: 'checked',
					label: '',
					class: 'text-center',
					sortable: false
				},
				{
					key: 'name',
					label: this.FormMSG(412, 'Last Name'),
					class: 'text-left w-16',
					sortable: false
				},
				{
					key: 'firstName',
					label: this.FormMSG(413, 'First Name'),
					class: 'text-left w-16',
					sortable: false
				},
				{
					key: 'phone',
					label: this.FormMSG(311, 'Phone'),
					class: 'text-left w-16',
					sortable: false
				},
				{
					key: 'email',
					label: this.FormMSG(414, 'Email'),
					class: 'text-left w-16',
					sortable: false
				},
				{
					key: 'departmentName',
					label: this.FormMSG(416, 'Department'),
					class: 'text-left w-16',
					sortable: false
				},
				{
					key: 'functionName',
					label: this.FormMSG(417, 'Function'),
					class: 'text-left w-16',
					sortable: false
				},
				{
					key: 'edit',
					label: this.FormMSG(315, 'Edit'),
					class: 'text-center'
				}
			];
		}
	},

	async created() {
		await this.getDepartment();
		this.allFunctions = [{ value: null, text: this.FormMSG(587, 'Please select a department') }];
		this.langStore = store.state.myProfile.appLanguage;
	},

	methods: {
		boundaryTreeSelect() {
			this.$nextTick(async () => {
				const dropdown = document.querySelectorAll('.vue-treeselect__menu');
				if (!dropdown) return;
				return new Promise((resolve, _) => resolve(dropdown)).then((element) => {
					if (element) {
						const newElement = element;
						newElement.forEach((el) => {
							const { height, width, top } = el.getBoundingClientRect();
							el.style.setProperty('position', 'fixed', 'important');
							el.style.setProperty('top', `${top}px`, 'important');
							el.style.setProperty('width', `${width}px`, 'important');
							el.style.setProperty('height', `${height}px`, 'important');
						});
						return newElement;
					}
				});
			});
		},
		sizeOfUsers() {
			if (this.globalUsers.length === this.totalOfUsers) {
				return (
					this.globalUsers.length.toString().padStart(2, '0') +
					' ' +
					this.FormMSG(3051, 'Of') +
					' ' +
					this.totalOfUsers.toString().padStart(2, '0') +
					' ' +
					this.FormMSG(3052, 'records') +
					' ' +
					this.FormMSG(3050, 'end of list')
				);
			}
			return (
				this.globalUsers.length.toString().padStart(2, '0') +
				' ' +
				this.FormMSG(3051, 'Of') +
				' ' +
				this.totalOfUsers.toString().padStart(2, '0') +
				' ' +
				this.FormMSG(3052, 'records')
			);
		},
		closeUserEdit(_item, _index) {
			this.dataList = this.dataList.map((item, index) => {
				if (index === _index) {
					return { ...item, isEdit: false };
				}
				return item;
			});
		},
		async editUser(_item, _index) {
			if (_item.isEdit) {
				this.$v.dataList.$touch();
				if (!this.$v.dataList.$each[_index].$invalid) {
					this.$bvModal
						.msgBoxConfirm(this.FormMSG(77, 'Would you like to update this user'), {
							title: this.FormMSG(78, 'Confirm'),
							size: 'sm',
							buttonSize: 'sm',
							okVariant: 'danger',
							okTitle: this.FormMSG(79, 'Yes'),
							cancelTitle: this.FormMSG(80, 'No'),
							footerClass: 'p-2',
							hideHeaderClose: false,
							centered: false
						})
						.then(async (res) => {
							if (res) {
								let _userId = +_item.id;
								let data = {
									email: _item.email,
									phone: _item.phone,
									deparmtent: _item.department,
									departmentName: _item.departmentName,
									function: _item.function,
									functionName: _item.functionName
								};
								let result = await updateUser(_userId, data);
								if (result) {
									this.createToastForMobile(this.FormMSG(81, 'Success'), this.FormMSG(82, 'User update with success'), '', 'success');
									let arrays = [];
									for (let i = 0; i < this.dataList.length; i++) {
										let index = i;
										if (+index === _index) {
											let _department = +this.dataList[_index].department;
											let response = await this.getDepFunctions(_department);
											let _mapFunctions = response !== undefined ? response : [];
											arrays.push({ ...this.dataList[_index], mapFunctions: _mapFunctions, isEdit: !this.dataList[_index].isEdit });
										} else {
											arrays.push(this.dataList[index]);
										}
									}
									this.dataList = arrays;
								} else {
									this.createToastForMobile(this.FormMSG(83, 'Error'), this.FormMSG(84, 'Error to update user'), '', 'error');
								}
							}
						})
						.catch((err) => console.log(err));
				} else {
					this.createToastForMobile(this.FormMSG(85, 'Warning'), this.FormMSG(86, 'Check the input which is not valid'), '', 'warning');
				}
			} else {
				let arrays = [];
				for (let i = 0; i < this.dataList.length; i++) {
					let index = i;
					if (+index === _index) {
						let _department = +this.dataList[_index].department;
						let response = await this.getDepFunctions(_department);
						let _mapFunctions = response !== undefined ? response : [];
						arrays.push({ ...this.dataList[_index], mapFunctions: _mapFunctions, isEdit: !this.dataList[_index].isEdit });
					} else {
						arrays.push(this.dataList[index]);
					}
				}
				this.dataList = arrays;
			}
		},
		async handleAddFunction(payload, index) {
			let _department = +payload.department;
			this.row_data = payload;
			let new_function = await addFunction(_department);
			if (new_function) {
				this.row_data = { ...payload, new_function, index };
				this.openModalFunction = !this.openModalFunction;
			}
		},
		handleShowModalFunction() {
			this.openModalFunction = true;
		},
		async handleCancelModalFunction() {
			let fonctionId = +this.row_data.new_function.id;
			let result = await deleteFunction(fonctionId, true);
			if (result) {
				this.openModalFunction = false;
				this.row_data = null;
			}
		},
		async handleSaveNewFunction(newFunction) {
			if (newFunction !== null) {
				let index = +newFunction.index;
				let item = newFunction.new_function;
				let id = +item.id;
				let message = item.message;
				let shortCode = item.shortCode;
				let prefix = item.prefix;
				let result = await updateFunction(id, message, null, shortCode, prefix, null, true);
				if (result) {
					this.openModalFunction = false;
					this.dataList[index].mapFunctions = [{ id: +item.id, label: item.message }, ...this.dataList[index].mapFunctions];
					let strTitle = this.FormMSG(301, 'Success');
					this.$bvToast.toast(this.FormMSG(302, 'Function create with success'), {
						title: strTitle,
						variant: 'success',
						toaster: 'b-toaster-top-center',
						solid: true
					});
				} else {
					let strTitle = this.FormMSG(303, 'Error');
					this.$bvToast.toast(this.FormMSG(304, 'Function error to create'), {
						title: strTitle,
						variant: 'error',
						toaster: 'b-toaster-top-center',
						solid: true
					});
				}
			}
		},
		async handleOptionSelectDepartment(payload, index) {
			let response = await this.getDepFunctions(+payload.id);
			let _item = {
				...this.dataList[index],
				mapFunctions: response !== undefined ? [...response] : [],
				department: +payload.id,
				departmentName: payload.label,
				function: +response[0].id,
				functionName: response[0].label
			};
			this.dataList = this.dataList.map((item, _index) => {
				if (_index === index) {
					return { ..._item };
				}
				return item;
			});
			this.dataListChecked = this.dataListChecked.map((item) => {
				if (item.email === this.dataList[index].email && item.name === this.dataList[index].name) {
					return { ...this.dataList[index] };
				}
				return item;
			});
		},
		async handleOptionSelectFunction(payload, index) {
			this.dataList[index] = { ...this.dataList[index], function: +payload.id, functionName: payload.label };
			this.dataListChecked = this.dataListChecked.map((item) => {
				if (item.email === this.dataList[index].email && item.name === this.dataList[index].name) {
					return { ...this.dataList[index] };
				}
				return item;
			});
		},
		async handleChangeDepartment(payload) {
			if (payload === null) {
				this.allFunctions = [{ value: null, text: this.FormMSG(587, 'Please select a department') }];
				this.dataForAddNoExisting.function = null;
			} else {
				await this.getFunctions(payload);
			}
		},
		async getFunctions(departementID) {
			const result = await getFunctions(+departementID);
			this.allFunctions = [{ value: null, text: this.FormMSG(258, 'Please select') }];

			result.map((option) => {
				this.allFunctions.push({
					value: +option.value,
					text: option.message
				});
			});
		},
		async getDepartment() {
			this.allDepartments = [{ value: null, text: this.FormMSG(258, 'Please select') }];
			await getDepartments(null, null).then((result) => {
				result.map((option) => {
					if (option.value !== this.depAdministration) {
						this.allDepartments.push({
							value: option.value,
							text: option.message
						});
					}
				});
			});
		},
		async handleChangeItemSelect(payload, item, index) {
			if (payload === false) {
				const indexFromDataSelected = _.findIndex(this.dataListChecked, (option) => option.indexDataList === index);

				if (indexFromDataSelected > -1) {
					this.dataListChecked.splice(indexFromDataSelected, 1);
				}

				this.selectAll = false;
				this.dataList[index].checked = false;
			} else if (payload === true) {
				this.dataListChecked.push({
					...item,
					indexDataList: index
				});

				if (this.dataListChecked.length === this.dataList.length) {
					this.selectAll = true;
				}
				this.dataList[index].checked = true;
				let _department = +this.dataList[index].department;
				let response = await this.getDepFunctions(_department);
				this.dataList[index].mapFunctions = response;
			}
		},
		async getDepFunctions(dep) {
			let functions = [...(await getFunctions(dep))].map((item) => ({ id: item.value, label: item.message }));
			return functions;
		},
		async handleClickAddSelected() {
			const actionForLoader = async () => {
				this.loadingAddSelected = true;
				const resultPrepareDataSelected = this.prepareDataSelectedToSend();
				await addUsersToProject(resultPrepareDataSelected.dataToSend);
				this.createToastForMobile(this.FormMSG(59, 'Success'), this.FormMSG(61, 'User(s) added successfully!'));
				this.dataList = resultPrepareDataSelected.newDataList;
				this.emitEventUsersAdded();
				this.dataListChecked = [];
				this.selectAll = false;
				this.loadingAddSelected = false;
			};
			await this.showLoader(actionForLoader, 'containerInviteNewUser');
			this.$emit('init-total:submitted');
		},
		prepareDataSelectedToSend() {
			let result = [];
			let newDataList = _.cloneDeep(this.dataList);
			for (let i = 0; i < this.dataListChecked.length; i++) {
				const element = this.dataListChecked[i];
				result.push({
					firstName: element.firstName,
					name: element.name,
					email: element.email,
					function: element.function,
					department: element.department,
					functionName: element.functionName,
					departmentName: element.departmentName,
					language: this.langStore,
					appLanguage: this.langStore
				});

				newDataList = newDataList.filter((option) => +option.id !== +element.id);
			}

			return {
				dataToSend: result,
				newDataList
			};
		},
		async handleChangeSelectAll(payload) {
			this.dataListChecked = [];
			if (payload === true) {
				const onSelectedAll = async () => {
					let arrays = [];
					for (let i = 0; i < this.dataList.length; i++) {
						let index = i;
						this.dataListChecked.push({
							...this.dataList[i],
							indexDataList: index
						});
						let _department = +this.dataList[index].department;
						let response = await this.getDepFunctions(_department);
						let _mapFunctions = response !== undefined ? response : [];
						arrays.push({ ...this.dataList[index], mapFunctions: _mapFunctions, checked: true });
					}
					this.dataList = arrays;
				};
				await this.showLoader(onSelectedAll, 'containerInviteNewUser');
			} else {
				const onDeselectedAll = () => {
					this.dataList = this.dataList.map((option) => {
						return {
							...option,
							mapFunctions: [],
							checked: false
						};
					});
				};
				await this.showLoader(onDeselectedAll, 'containerInviteNewUser');
			}
		},
		async handleOk() {
			if (this.searchExisting === false) {
				this.emitEventClose();
			} else {
				this.$v.$touch();

				if (!this.$v.$invalid) {
					this.loadingSubmit = true;

					const functionFinded = _.find(this.allFunctions, (option) => option.value === this.dataForAddNoExisting.function);
					const departmentFinded = _.find(this.allDepartments, (option) => option.value === this.dataForAddNoExisting.department);

					let result = await addUsersToProject(
						[
							{
								firstName: this.dataForAddNoExisting.firstName,
								name: this.dataForAddNoExisting.name,
								email: this.dataForAddNoExisting.email,
								department: this.dataForAddNoExisting.department,
								function: this.dataForAddNoExisting.function,
								phone: this.dataForAddNoExisting.phone,
								departmentName: departmentFinded.text,
								functionName: functionFinded.text
							}
						],
						true
					)
						.then((response) => {
							if (response) {
								this.createToastForMobile(this.FormMSG(812, 'Success'), this.FormMSG(813, 'User added successfully!'));
								this.loadingSubmit = false;
								this.emitEventUsersAdded();
								this.emitEventClose();
								return true;
							}
							{
								this.createToastForMobile(this.FormMSG(814, 'Error'), this.FormMSG(815, 'Email or phone number already used'), '', 'danger');
								this.loadingSubmit = false;
								return false;
							}
						})
						.catch((e) => {
							this.createToastForMobile(this.FormMSG(814, 'Error'), this.FormMSG(81, 'Error to add user'));
							this.loadingSubmit = false;
							this.emitEventUsersAdded();
							this.emitEventClose();
						});
					return result;
				}
			}
		},
		emitEventUsersAdded() {
			this.$emit('invite-new-user:users-added');
		},
		async handleKeyUpEnter() {
			if (this.filter.length > 2) {
				this.recordOffset = 0;
				this.recordLimit = 50;
				this.alreadyLastData = false;
				this.isActiveSearch = true;
				await this.getUsers();
			}
		},
		async getTotalUsers() {
			this.totalOfUsers = await getTotalUsersCount(null, false, true);
		},
		async getUsers(pushData = false) {
			if (!pushData) {
				this.dataList = [];
				this.dataListChecked = [];
			}

			const actionForLoader = async () => {
				if (this.alreadyLastData === false) {
					let _recordLimit = null;
					let _recordOffset = null;
					let _filter = null;
					if (this.filter.length > 2) {
						_recordLimit = null;
						_recordOffset = null;
						_filter = this.filter;
					} else {
						_recordLimit = this.recordLimit;
						_recordOffset = this.recordOffset;
						_filter = null;
					}
					const result = [...(await getUsersByLicence(_filter, _recordLimit, _recordOffset))].map((item) => ({ ...item, isEdit: false }));
					if (result.length > 0) {
						if (this.selectAll) {
							let arrays = [];
							let size = this.dataListChecked.length;
							for (let i = 0; i < result.length; i++) {
								let index = i;
								this.dataListChecked = [...this.dataListChecked, { ...result[i], indexDataList: index + size }];
								let _department = +result[i].department;
								let response = await this.getDepFunctions(_department);
								let _mapFunctions = response !== undefined ? response : [];
								arrays.push({ ...result[i], mapFunctions: _mapFunctions, checked: true });
							}
							this.dataList = [...this.dataList, ...arrays];
						} else {
							result.map((option) => {
								this.dataList.push({
									...option,
									mapFunctions: [],
									checked: false
								});
							});
						}
						if (this.filter.length < 2) {
							this.globalUsers = this.dataList;
						}
					} else {
						this.alreadyLastData = true;
					}
				}
			};

			await this.showLoader(actionForLoader, 'containerInviteNewUser');
		},
		async resetFilter() {
			this.filter = '';
			this.recordOffset = 0;
			this.alreadyLastData = false;
			this.dataList = [];
			await this.getUsers();
		},
		async handleShow() {
			setTimeout(() => {
				const element = document.querySelector('#containerInviteNewUser .b-table-sticky-header');
				let result =
					element &&
					element.addEventListener('scroll', async () => {
						const scrollTop = element.scrollTop;
						const scrollHeight = element.scrollHeight;
						const clientHeight = element.clientHeight;
						if (this.filter.length === 0 && this.globalUsers.length < this.totalOfUsers && scrollTop) {
							if (scrollTop + clientHeight + 1 >= scrollHeight) {
								if (this.alreadyLastData === false) {
									this.recordOffset += 1;
									await this.getUsers(true);
								}
							}
						}
					});
				return result;
			}, 1000);
		},
		toggleSearchExisting() {
			this.searchExisting = !this.searchExisting;
			if (this.searchExisting === false) {
				this.handleShow();
			}
		},
		handleCancel() {
			clearTimeout();

			this.emitEventClose();
		},
		emitEventClose() {
			this.dataList = [];
			this.dataListChecked = [];
			this.filter = '';
			this.dataForAddNoExisting = {
				name: '',
				firstName: '',
				email: '',
				phone: '',
				department: null,
				function: null
			};
			this.searchExisting = false;

			this.$emit('invite-new-user:close');
		}
	}
};
</script>
<style scoped>
.btn-plus {
	border-radius: 5px;
	background-color: #fff;
	border: 1px solid #b8b9ba;
}
.gap-4 {
	gap: 4px;
}
.gap-8 {
	gap: 8px;
}
.btn-custom {
	border-radius: 4px !important;
	width: 80px;
}
.dialog-custom {
	max-width: 90% !important;
}
</style>
<style>
.w-16 {
	width: 15.2% !important;
}
.end-of-list {
	color: rgba(6, 38, 62, 0.84);
	font-weight: 900;
	font-size: 0.95rem;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.title,
        "header-class": "header-class-modal-doc-package",
        size: "xs",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: {
        show: _vm.handleShowModalFunction,
        cancel: _vm.handleCancelModalFunction,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row align-items-center justify-content-end w-100 gap-8",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-custom",
                      attrs: { variant: "outline-dark", size: "sm" },
                      on: { click: _vm.handleCancelModalFunction },
                    },
                    [_vm._v(" " + _vm._s(_vm.FormMSG(12, "Close")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-custom",
                      attrs: {
                        disabled: _vm.$v.new_function.$invalid,
                        variant: "outline-primary",
                        size: "sm",
                      },
                      on: { click: _vm.handleSaveNewFunction },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(13, "Ok")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      [
        _c("div", { staticClass: "d-flex flex-column gap-8 w-100" }, [
          _c("div", { staticClass: "d-flex flex-row align-items-center" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.FormMSG(1, "Department name:"))),
            ]),
            _vm._v("\n\t\t\t\t \n\t\t\t\t"),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.row_data !== null
                      ? _vm.row_data.departmentName
                      : _vm.FormMSG(2, "None")
                  )
              ),
            ]),
          ]),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center flex-row gap-8" },
              [
                _c("label", { staticClass: "w-25" }, [
                  _vm._v(_vm._s(_vm.FormMSG(3, "Prefix"))),
                ]),
                _c("b-form-input", {
                  staticClass: "w-75",
                  class: { "is-invalid": !_vm.$v.new_function.prefix.required },
                  attrs: { placeholder: _vm.FormMSG(4, "Enter a prefix name") },
                  model: {
                    value: _vm.new_function.prefix,
                    callback: function ($$v) {
                      _vm.$set(_vm.new_function, "prefix", $$v)
                    },
                    expression: "new_function.prefix",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "d-flex ml-26" }, [
              !_vm.$v.new_function.prefix.required
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(_vm._s(_vm.FormMSG(5, "Prefix is required"))),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center flex-row gap-8" },
              [
                _c("label", { staticClass: "w-25" }, [
                  _vm._v(_vm._s(_vm.FormMSG(6, "Function"))),
                ]),
                _c("b-form-input", {
                  staticClass: "w-75",
                  class: {
                    "is-invalid": !_vm.$v.new_function.message.required,
                  },
                  attrs: {
                    placeholder: _vm.FormMSG(7, "Enter a function name"),
                  },
                  model: {
                    value: _vm.new_function.message,
                    callback: function ($$v) {
                      _vm.$set(_vm.new_function, "message", $$v)
                    },
                    expression: "new_function.message",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "d-flex ml-26" }, [
              !_vm.$v.new_function.message.required
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(_vm._s(_vm.FormMSG(8, "Function name is required"))),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center flex-row gap-8" },
              [
                _c("label", { staticClass: "w-25" }, [
                  _vm._v(_vm._s(_vm.FormMSG(9, "Short code"))),
                ]),
                _c("b-form-input", {
                  staticClass: "w-75",
                  class: {
                    "is-invalid": !_vm.$v.new_function.shortCode.required,
                  },
                  attrs: { placeholder: _vm.FormMSG(10, "Enter a short code") },
                  model: {
                    value: _vm.new_function.shortCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.new_function, "shortCode", $$v)
                    },
                    expression: "new_function.shortCode",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "d-flex ml-26" }, [
              !_vm.$v.new_function.shortCode.required
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(_vm._s(_vm.FormMSG(11, "Short code is required"))),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isGrouping
    ? _c(
        "div",
        {
          staticClass:
            "d-flex flex-row justify-content-center align-items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-content-center align-items-center pd-1 mr-1 ml-1",
              staticStyle: { width: "100%", height: "100px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-start w-100 show-hover gap-2",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row",
                      staticStyle: { width: "18%" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: {
                            disabled: _vm.checkSomme(
                              _vm.item.tsDaysTotal,
                              _vm.FIMALAC_PROJECT_FLAG.SALARY
                            ),
                            size: "sm",
                            id: `tooltip-${_vm.item.id}-salaryTotalByCostCenter`,
                          },
                        },
                        [
                          _c(_vm.getLucideIcon(_vm.ICONS.INFO.name), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.INFO.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tooltip",
                    {
                      staticClass: "v-bootstrap-tooltip-custom",
                      attrs: {
                        target: `tooltip-${_vm.item.id}-salaryTotalByCostCenter`,
                        placement: "left",
                      },
                    },
                    [
                      _c("custom-tooltip", {
                        attrs: {
                          "is-row": true,
                          item: _vm.item,
                          type: _vm.FIMALAC_PROJECT_FLAG.SALARY,
                          isProduction: _vm.isProduction,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row",
                      staticStyle: { width: "82%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-bold fs-12 mt-1",
                          staticStyle: { "text-transform": "capitalize" },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(1, "Salary")) + " :")]
                      ),
                      _vm._v("\n\t\t\t\t \n\t\t\t\t"),
                      _c(
                        "div",
                        { staticClass: "font-weight-bold fs-12 mt-1" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getCurrencyRow(
                                _vm.item,
                                _vm.FIMALAC_PROJECT_FLAG.SALARY
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-start w-100 show-hover",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row",
                      staticStyle: { width: "18%" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: {
                            disabled: _vm.checkSomme(
                              _vm.item.tsDaysTotal,
                              _vm.FIMALAC_PROJECT_FLAG.PRIME
                            ),
                            size: "sm",
                            id: `tooltip-${_vm.item.id}-primeTotalByCostCenter`,
                          },
                        },
                        [
                          _c(_vm.getLucideIcon(_vm.ICONS.INFO.name), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.INFO.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tooltip",
                    {
                      staticClass: "v-bootstrap-tooltip-custom",
                      attrs: {
                        target: `tooltip-${_vm.item.id}-primeTotalByCostCenter`,
                        placement: "left",
                      },
                    },
                    [
                      _c("custom-tooltip", {
                        attrs: {
                          "is-row": true,
                          item: _vm.item,
                          type: _vm.FIMALAC_PROJECT_FLAG.PRIME,
                          isProduction: _vm.isProduction,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row",
                      staticStyle: { width: "82%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-bold fs-12 mt-1",
                          staticStyle: { "text-transform": "capitalize" },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(2, "Prime")) + " :")]
                      ),
                      _vm._v("\n\t\t\t\t \n\t\t\t\t"),
                      _c(
                        "div",
                        { staticClass: "font-weight-bold fs-12 mt-1" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getCurrencyRow(
                                _vm.item,
                                _vm.FIMALAC_PROJECT_FLAG.PRIME
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-start w-100 show-hover",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row",
                      staticStyle: { width: "18%" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: {
                            disabled: _vm.checkSomme(
                              _vm.item.tsDaysTotal,
                              _vm.FIMALAC_PROJECT_FLAG.DEFRAYAL
                            ),
                            size: "sm",
                            id: `tooltip-${_vm.item.id}-defraiment`,
                          },
                        },
                        [
                          _c(_vm.getLucideIcon(_vm.ICONS.INFO.name), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.INFO.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tooltip",
                    {
                      staticClass: "v-bootstrap-tooltip-custom",
                      attrs: {
                        target: `tooltip-${_vm.item.id}-defraiment`,
                        placement: "left",
                      },
                    },
                    [
                      _c("custom-tooltip", {
                        attrs: {
                          "is-row": true,
                          item: _vm.item,
                          type: _vm.FIMALAC_PROJECT_FLAG.DEFRAYAL,
                          isProduction: _vm.isProduction,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row",
                      staticStyle: { width: "82%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-bold fs-12 mt-1",
                          staticStyle: { "text-transform": "capitalize" },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(3, "Defraiment")) + " :")]
                      ),
                      _vm._v("\n\t\t\t\t \n\t\t\t\t"),
                      _c(
                        "div",
                        { staticClass: "font-weight-bold fs-12 mt-1" },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.getCurrencyRow(
                                  _vm.item,
                                  _vm.FIMALAC_PROJECT_FLAG.DEFRAYAL
                                )
                              ) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-start w-100 show-hover",
                },
                [
                  _c("div", {
                    staticClass: "d-flex flex-row",
                    staticStyle: { width: "18%" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row",
                      staticStyle: { width: "82%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-bold fs-12 mt-1",
                          staticStyle: { "text-transform": "capitalize" },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(4, "Brut")) + " :")]
                      ),
                      _vm._v("\n\t\t\t\t \n\t\t\t\t"),
                      _c(
                        "div",
                        { staticClass: "font-weight-bold fs-12 mt-1" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getCurrencyRow(
                                _vm.item,
                                _vm.FIMALAC_PROJECT_FLAG.GROSS
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm.isGrouping && _vm.showVerified
    ? _c(
        "div",
        {
          staticClass:
            "d-flex flex-column align-items-center justify-content-start default-content-paie",
          style: { width: `${_vm.widthCell + 60}px !important` },
          attrs: { id: "grouping-paie" },
        },
        [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-row",
                  staticStyle: { width: "18%" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn bg-transparent border-0",
                      attrs: {
                        disabled: _vm.checkSomme(
                          _vm.totalGrouping,
                          _vm.FIMALAC_PROJECT_FLAG.GROSS
                        ),
                        size: "sm",
                        id: `tooltip-standard`,
                      },
                    },
                    [
                      _c(_vm.getLucideIcon(_vm.ICONS.INFO.name), {
                        tag: "component",
                        attrs: {
                          color: _vm.ICONS.INFO.color,
                          size: 16,
                          "stroke-width": 2.25,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tooltip",
                {
                  staticClass: "v-bootstrap-tooltip-custom",
                  attrs: { target: `tooltip-standard`, placement: "left" },
                },
                [
                  _c("custom-tooltip", {
                    attrs: {
                      "is-row": false,
                      rowItem: _vm.totalGrouping,
                      type: _vm.FIMALAC_PROJECT_FLAG.SALARY,
                      isProduction: _vm.isProduction,
                    },
                  }),
                ],
                1
              ),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.FormMSG(5645, "Standard")) +
                    " : " +
                    _vm._s(
                      _vm.getCurrency(
                        _vm.totalGrouping,
                        _vm.FIMALAC_PROJECT_FLAG.SALARY
                      )
                    )
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-row",
                  staticStyle: { width: "18%" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn bg-transparent border-0",
                      attrs: {
                        disabled: _vm.checkSomme(
                          _vm.totalGrouping,
                          _vm.FIMALAC_PROJECT_FLAG.PRIME
                        ),
                        size: "sm",
                        id: `tooltip-prime`,
                      },
                    },
                    [
                      _c(_vm.getLucideIcon(_vm.ICONS.INFO.name), {
                        tag: "component",
                        attrs: {
                          color: _vm.ICONS.INFO.color,
                          size: 16,
                          "stroke-width": 2.25,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tooltip",
                {
                  staticClass: "v-bootstrap-tooltip-custom",
                  attrs: { target: `tooltip-prime`, placement: "left" },
                },
                [
                  _c("custom-tooltip", {
                    attrs: {
                      "is-row": false,
                      rowItem: _vm.totalGrouping,
                      type: _vm.FIMALAC_PROJECT_FLAG.PRIME,
                      isProduction: _vm.isProduction,
                    },
                  }),
                ],
                1
              ),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.FormMSG(5646, "Prime")) +
                    " : " +
                    _vm._s(
                      _vm.getCurrency(
                        _vm.totalGrouping,
                        _vm.FIMALAC_PROJECT_FLAG.PRIME
                      )
                    )
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-row",
                  staticStyle: { width: "18%" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn bg-transparent border-0",
                      attrs: {
                        disabled: _vm.checkSomme(
                          _vm.totalGrouping,
                          _vm.FIMALAC_PROJECT_FLAG.DEFRAYAL
                        ),
                        size: "sm",
                        id: `tooltip-defraiment`,
                      },
                    },
                    [
                      _c(_vm.getLucideIcon(_vm.ICONS.INFO.name), {
                        tag: "component",
                        attrs: {
                          color: _vm.ICONS.INFO.color,
                          size: 16,
                          "stroke-width": 2.25,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tooltip",
                {
                  staticClass: "v-bootstrap-tooltip-custom",
                  attrs: { target: `tooltip-defraiment`, placement: "left" },
                },
                [
                  _c("custom-tooltip", {
                    attrs: {
                      "is-row": false,
                      rowItem: _vm.totalGrouping,
                      type: _vm.FIMALAC_PROJECT_FLAG.DEFRAYAL,
                      isProduction: _vm.isProduction,
                    },
                  }),
                ],
                1
              ),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.FormMSG(5647, "Defraiment")) +
                    " : " +
                    _vm._s(
                      _vm.getCurrency(
                        _vm.totalGrouping,
                        _vm.FIMALAC_PROJECT_FLAG.DEFRAYAL
                      )
                    )
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "item" }, [
            _c("div", {
              staticClass: "d-flex flex-row",
              staticStyle: { width: "18%" },
            }),
            _c("div", [
              _vm._v(
                _vm._s(_vm.FormMSG(5648, "Brut")) +
                  " : " +
                  _vm._s(
                    _vm.getCurrencyBrut(
                      _vm.totalGrouping,
                      _vm.FIMALAC_PROJECT_FLAG.GROSS
                    )
                  )
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }